/* eslint-disable @typescript-eslint/quotes */
import React from 'react'

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function BrandaScorePopUp ({
  isOpen,
  onClose,
  onConfirm
}: Props) {
  return (
    <div className={`flex fixed top-0 right-0 left-0 z-50 backdrop-blur-sm justify-center items-center w-full md:inset-0 h-full ${isOpen ? '' : 'hidden'}`}>
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative">
        <div className="branda-score w-[350px] md:w-[600px] h-72 rounded-3xl shadow dark:bg-gray-700  mt-2 pt-14">
          <div className="flex flex-col items-center justify-center p-2 md:p-3 rounded-t dark:border-gray-600 gap-4">
            <p className="font-light text-base md:text-lg text-black p-0 mr-5">
              Before your first task,
            </p>
            <p className='text-sm md:text-2xl text-black font-semibold md:font-bold'>
              Let&apos;s calculate your personal Branda Score!
            </p>
            <p className="text-sm md:text-base font-light md:text-lg text-black p-0">
              No worries, this is a one time proccess &#128077;
            </p>
          </div>
          <div className="p-2 md:px-5 pt-6 md:pt-3 space-y-4">
            <div className="flex justify-center items-center">
              <button
                className="px-10 py-3 w-48 text-sm md:text-xl font-semibold text-white rounded-2xl bg-purple-600"
                onClick={onConfirm}
              >
                Start
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}
