/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable  */
import HomeDesktopImg from '../../../img/home-signout.png'
import HomeMobileImg from '../../../img/home-mobile.png'
import Image from '../../atoms/Image/Image'
import { useEffect, useState } from 'react'
import { useAppSelector } from 'app/hooks'
import { Link } from 'react-router-dom'
import Button from 'components/atoms/Button/Button'
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown'
import EndSubscriptionModal from '../ProfileForm/Modals/EndSubscriptionModal'
import EndTrialModal from '../ProfileForm/Modals/EndTrialModal'

interface Props {
  className: string
  onScrollDown: () => void
}

export default function HeadersNonSignInUsers ({ className, onScrollDown }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [homeImage, setHomeImage] = useState(HomeDesktopImg)
  const [userSignedIn, setUserSignedIn] = useState('')
  const [userSignedInUrl, setUserSignedInUrl] = useState('')
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isTrialModalOpen, setIsTrialModalOpen] = useState(false)

const toggleTrialModalState = () => {
    const subscription = currentUser?.subscription[0]
    const today = new Date()
    const todayDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)
    setIsTrialModalOpen(!!(subscription && subscription.valid_until < todayDate && subscription.type === -1))
}

 const toggleModal = () => {
    if (currentUser && currentUser.subscription && currentUser.subscription.length > 0) {
      if (!currentUser.subscription[0].is_active) {
        setIsModalOpen(true)
      } else {
        setIsModalOpen(false)
      }
    }
  }

  const closeModal = () => {
    setIsModalOpen(false)
  }

    const closeTrialModal = () => {
        setIsTrialModalOpen(false)
    }


  const updateScreenWidth = () => {
    setIsScreenWidthAbove768(true)
    if (window.innerWidth < 600) {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    if (currentUser) {
      if (currentUser?.has_finished_quiz) {
        setUserSignedIn('Go to weekly goals')
        setUserSignedInUrl('/all-weekly-goals')
      } else if (!currentUser?.has_finished_steps) {
          setUserSignedIn('Resume registration')
          setUserSignedInUrl('/quiz')
      } else {
        setUserSignedIn('Go to dashboard')
        setUserSignedInUrl('/branda-score')
      }
      if(currentUser.subscription && currentUser.subscription.length > 0) {
        setIsModalOpen(!currentUser?.subscription[0].is_active)
      }

        toggleTrialModalState()

    } else {
      setUserSignedIn('Start My Free Trial!')
      setUserSignedInUrl('/quiz')
    }
  }, [currentUser])

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  useEffect(() => {
    setHomeImage(isScreenWidthAbove768 ? HomeDesktopImg : HomeMobileImg)
  }, [isScreenWidthAbove768])

  return (
    <div className={className}>
      <Image className="absolute h-auto sm:h-full w-full -z-1 object-cover object-top top-0 rounded-b-2xl sm:rounded-b-3xl" src={homeImage} />
      <div className='flex flex-col md:flex-row justify-end md:justify-between md:items-end lg:items-center h-full md:mt-6 mb-28'>
        <div className="flex flex-col items-start">
          <div className="text-white font-light mt-14 md:mt-28 lg:mt-48 lg:mt-5 md:font-normal text-sm md:text-2xl ml-5 md:ml-12">Welcome to myBranda®</div>
          <div className='main_header_home text-white ml-5 md:ml-10 font-bold hidden lg:block text-8xl'>Hello Brand</div>
          <div className='main_header_home text-white ml-5 md:ml-10 font-bold lg:hidden text-7xl md:text-8xl'>Hello <br/> Brand</div>
          <Link to={userSignedInUrl} className='ml-5 mb-2 md:mb-0 md:ml-10'>
          <Button
            text={userSignedIn}
            className="brand_me_up_and_quiz_button text-white font-normal rounded-lg text-sm md:text-lg p-3 md:px-6 md:py-4 mt-4 md:mt-10 text-center"
          />
        </Link>
        </div>
        <div className="flex flex-col mb-0 ml-6 mt-2 md:mr-10">
          <a className="text-gray-200 menuhome text-medium md:text-xl mb-1 md:mb-5" target='_blank' href="https://www.mybranda.com/" rel="noreferrer">About Us</a>
          <a className="text-gray-200 menuhome menuhome-plans text-medium md:text-xl mb-1 md:mb-5" target='_blank' href="https://whatsmybranda.com/subscription-home" rel="noreferrer">Our Plans</a>
          <a className="text-gray-200 menuhome text-medium md:text-xl mb-0 md:mb-0" target='_blank' href="https://whatsmybranda.com/contact-us" rel="noreferrer">Contact Us</a>
        </div>
        <div className="flex justify-center md:hidden relative">
          <KeyboardDoubleArrowDownIcon className="text-white arrow-down absolute" onClick={onScrollDown} />
        </div>
      </div>
      <EndSubscriptionModal
        isOpen={isModalOpen}
        onClose={toggleModal}
        onCloseModal={closeModal}
      />
    <EndTrialModal
        isOpen={isTrialModalOpen}
        onCloseModal={closeTrialModal}
    />
    </div>
  )
}
