/* eslint-disable react/jsx-key */
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
/* eslint-disable @typescript-eslint/no-floating-promises */

import { useEffect, useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import subscriptionPlans from '../../../temp/subscriptionplansinfo'
import subscriptionPlansHome from '../../../temp/subscriptionplansinfoHome'
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react'

// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'

// Import required modules
import { Pagination, Navigation } from 'swiper/modules'
import { Link, useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { notifications, users } from 'app/services/Api'
import { toast } from 'react-toastify'
import PlanChangeModal from '../ProfileForm/Modals/PlanChangeModal'
import { setCurrentUser } from 'app/quiz/quizSlice'
import BackButton from 'components/atoms/BackButton/BackButton'
interface Props {
  homeSubscription?: boolean
}

interface Subscription {
  id: number
  branda_type: string
  price: string
  img: string
  title: string
  paypro_url: string
  product_id: number
}

export default function SubscriptionPageInfo ({ homeSubscription = false }: Props) {
  const dispatch = useAppDispatch()
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [waitingList, setWaitingList] = useState('Join Waitlist!')
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [clickedSubscription, setClickedSubscription] = useState<number | null>(null)
  const [subscriptionsModalInfo, setSubscriptionsModalInfo] = useState<Record<number, { isOpen: boolean, chosenPlan: string, chosenPrice: string }>>({})
  const payProStarId = 88434
  const payProBoosterId = 83053
  const firstName = currentUser?.first_name ?? ''
  const lastName = currentUser?.last_name ?? ''
  let payProExtraParams = !homeSubscription ? `&billing-email=${currentUser?.email}&billing-country=${currentUser?.country}&billing-first-name=${firstName}` : ''
  const [backPage, setBackPage] = useState('')
  const [checkoutPage, setCheckoutPage] = useState<Window | null>(null)
  const [clickedProductId, setClickedProductId] = useState<number | null>(null)

  const navigate = useNavigate()
  const handleBackPage = () => {
    navigate(-1)
  }

  if (lastName.length > 0) {
    payProExtraParams += `&billing-last-name=${lastName}`
  }

  const subscriptionPlanChange = async (subscriptionId: number) => {
    let updatedPlanId = 0
    if (subscriptionId === 0) {
      updatedPlanId = payProBoosterId
    } else if (subscriptionId === 1) {
      updatedPlanId = payProStarId
    }
    await users.updateUserBilling(updatedPlanId, 'update', '', true)
    await users.getCurrentUser(true).then((response) => {
      if (!response.response) {
        dispatch(setCurrentUser(response))
      } else {
        dispatch(setCurrentUser(null))
      }
    })
      .catch((err) => {
        console.log(err)
      })
    toast.success('Successfully updated your subscription!')
  }

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    if (checkoutPage) {
      const checkIfClosed = setInterval(() => {
        if (currentUser && checkoutPage?.closed && clickedProductId) {
          void notifications.createLeadNotifications(
            currentUser.email,
            clickedProductId
          )
          clearInterval(checkIfClosed)
        }
      }, 5000)

      return () => {
        clearInterval(checkIfClosed)
        window.removeEventListener('resize', updateScreenWidth)
      }
    }

    return () => window.removeEventListener('resize', updateScreenWidth)
  }, [checkoutPage])

  const addToWaitingList = async () => {
    try {
      await users.addBrandWaitingList(currentUser?.email ?? '').then(response => {
        if (!response.response) {
          setWaitingList('Registered')
        } else {
          setWaitingList('Registered Before')
        }
      })
        .catch(err => {
          console.log(err)
          toast.error('User Not Found')
        })
    } catch (error) {
      console.error('Error adding to the Waiting List:', error)
    }
  }

  function renderSubscriptionHomePlans () {
    // @ts-expect-error it was here
    return renderSubscriptions(subscriptionPlansHome)
  }

  function renderSubscriptionPlans () {
    return renderSubscriptions(subscriptionPlans)
  }

  const getSubscriptionClassName = (subscriptionId: number) => {
    const userSignedIn = currentUser != null
    const hasSubscription = userSignedIn && currentUser.subscription?.[0]?.type === subscriptionId && currentUser?.subscription[0].is_active
    const activeClasses = '-mt-5 scale-105 bg-purple-100 shadow-md shadow-purple-300'

    if (userSignedIn && hasSubscription) {
      return activeClasses
    }

    if (subscriptionId === -1 && currentUser?.subscription[0]?.type === -1) {
      return activeClasses
    }

    if (userSignedIn && (currentUser.subscription?.[0] == null || !currentUser.subscription?.[0].is_active) && subscriptionId === 1) {
      return activeClasses
    }

    if (!userSignedIn && subscriptionId === 1) {
      return activeClasses
    }

    return 'hover:scale-105 hover:border-purple-300 hover:border hover:bg-purple-50 hover:shadow-md hover:shadow-purple-100'
  }

  const getSubscriptionMobileClassName = (subscriptionId: number) => {
    const userSignedIn = currentUser != null
    const hasSubscription = userSignedIn && currentUser.subscription?.[0]?.type === subscriptionId && currentUser?.subscription[0].is_active
    const activeClasses = 'mt-3 scale-105 rounded-xl border-purple-400 border bg-purple-100'

    if (userSignedIn && hasSubscription) {
      return activeClasses
    } else if (userSignedIn && ((currentUser.subscription?.[0]) == null || !currentUser.subscription?.[0].is_active) && subscriptionId === 1) {
      return activeClasses
    } else if (!userSignedIn && subscriptionId === 1) {
      return activeClasses
    } else {
      return ''
    }
  }

  const getSubscriptionColor = (subscriptionId: number) => {
    return subscriptionId === 0 ? '#E95EA2' : subscriptionId === 1 ? '#000' : '#6333B5'
  }

  const renderActiveStatus = (subscriptionId: number) => {
    let isSubscriptionActive = false
    console.log(currentUser?.subscription[0])
    if (subscriptionId === -1 && currentUser?.subscription[0]?.type === null) {
      isSubscriptionActive = true
    } else if (currentUser?.subscription[0]?.type === subscriptionId && currentUser?.subscription[0].is_active) {
      isSubscriptionActive = true
    }

    if (isSubscriptionActive) {
      return (
          <p className='ml-0 md:ml-5 mt-6 font-bold text-black'>Active</p>
      )
    }

    return null
  }

  const handleSubscriptionClick = (subscriptionId: number) => {
    if (subscriptionId === 2) {
      setClickedSubscription(subscriptionId)
    }
  }

  const manageSubscriptionUpdate = (subscriptionId: number, plan: string, price: string) => {
    if (subscriptionId === -1) {
      return null
    }

    const updatedInfo = { isOpen: true, chosenPlan: plan, chosenPrice: price }
    setSubscriptionsModalInfo(prevState => ({ ...prevState, [subscriptionId]: updatedInfo }))
  }

  const openWindow = (url: string, productId: number) => {
    const checkout = window.open(url, '_blank')
    setCheckoutPage(checkout)
    setClickedProductId(productId)
  }

  function renderSubscriptions (subscriptions: Subscription[]) {
    return subscriptions.map((subscription) => (
      <div key={subscription.id}>
        <div
          className={`relative rounded-2xl p-4 ${getSubscriptionClassName(subscription.id)}`}
          onClick={() => { handleSubscriptionClick(subscription.id) }}
        >
          <div className='relative'>
            {(currentUser?.subscription?.[0]?.type != null) && currentUser?.subscription[0]?.type !== subscription.id && subscription.id !== 2 && (
              <span key={subscription.id} onClick={() => manageSubscriptionUpdate(subscription.id, subscription.branda_type, subscription.price)} className="mt-4 absolute w-full h-full top-0"></span>
            )}

            {currentUser?.subscription?.[0]?.type === -1 && subscription.id !== 2 && (
                <span className="mt-4 absolute w-full h-full top-0" key={subscription.id} onClick={() => openWindow(subscription.paypro_url + payProExtraParams, subscription.product_id)}></span>
            )}

            {homeSubscription && (<Link key={subscription.id} to='/quiz' className="mt-4 absolute w-full h-full top-0" />)}

            <img src={subscription.img} alt={`Subscription ${subscription.id}`} className="w-screen" />
          </div>

          {(clickedSubscription === null || clickedSubscription !== subscription.id)
            ? (
                <>
                  {renderActiveStatus(subscription.id)}
                  <p className="font-normal text-base ml-5 leading-5 text-black pt-4 md:pb-3">The Plan Includes</p>
                  <div className="relative  font-sans ml-5 font-manrope text-base font-normal text-base leading-6 text-gray-500  hover:text-black block  mb-6">
                    {subscription.title.split('\n').map((line, index) => (
                      <p key={index} className='text-sm sm:text-base md:text-sm lg:text-base mt-6 flex items-center'>
                        <FontAwesomeIcon
                          icon={solid('circle-check')}
                          style={{ color: `${getSubscriptionColor(subscription.id)}`, alignSelf: 'center' }}
                          className='mr-2'
                        />
                        {line}
                      </p>
                    ))}
                  </div>
                </>
              )
            : (renderMasterPlan())}
        </div>
        <PlanChangeModal
           isOpen={subscriptionsModalInfo[subscription.id]?.isOpen || false}
           onClose={() => setSubscriptionsModalInfo(prevState => ({ ...prevState, [subscription.id]: { ...prevState[subscription.id], isOpen: false } }))}
           onConfirm={() => {
             setSubscriptionsModalInfo(prevState => ({ ...prevState, [subscription.id]: { ...prevState[subscription.id], isOpen: false } }))
             subscriptionPlanChange(subscription.id)
           }}
           chosenPlan={subscriptionsModalInfo[subscription.id]?.chosenPlan || ''}
           chosenPrice={subscriptionsModalInfo[subscription.id]?.chosenPrice || ''}
        />
      </div>
    ))
  }

  function renderMasterPlan () {
    return <div>
      <div className="relative box-border font-sans font-manrope text-base font-normal text-base leading-6 text-gray-500  hover:text-black block">
        <p className='text-base mt-6 flex items-center rounded border border-purple-300 p-3 text-center text-gray-600'>
          This plan will be available soon!
          You can start with other plans; we&apos;ll let you know when it&apos;s available.
        </p>
      </div>
      <div className="flex justify-center">
        <button onClick={addToWaitingList} type="button"
          className="brand_me_up_and_quiz_button text-white font-normal rounded-lg text-sm md:text-lg p-3 md:px-5 md:py-2 mt-5 md:mt-10 text-center">
          {waitingList}
        </button>
      </div>
    </div>
  }

  function renderMobile (subscriptions: Subscription[]) {
    return subscriptions.map((subscription) => (
      <SwiperSlide
        key={subscription.id}
        className={`relative p-4 md:p-4 ${getSubscriptionMobileClassName(subscription.id)} `}
        onClick={() => { handleSubscriptionClick(subscription.id) }}>
        <div className='relative'>
          {(currentUser?.subscription?.[0]?.type != null) && currentUser?.subscription[0]?.type !== subscription.id && subscription.id !== 2 && (
            <span key={subscription.id} onClick={() => manageSubscriptionUpdate(subscription.id, subscription.branda_type, subscription.price)} className="mt-4 absolute w-full h-full top-0"></span>
          )}
          {currentUser?.subscription?.[0]?.type === -1 && subscription.id !== 2 && (
              <span className="mt-4 absolute w-full h-full top-0" key={subscription.id} onClick={() => openWindow(subscription.paypro_url + payProExtraParams, subscription.product_id)}></span>
          )}
        {homeSubscription && (<Link key={subscription.id} to='/quiz' className="mt-4 absolute w-full h-full top-0" />)}
          <img src={subscription.img} alt={`Subscription ${subscription.id}`} className="w-screen" />
        </div>
        {(clickedSubscription === null || clickedSubscription !== subscription.id)
          ? (
            <>
              {renderActiveStatus(subscription.id)}
              <p className="font-normal text-base leading-5 text-black pt-4 md:pb-3">The plan includes</p>
              <div className="relative font-sans font-manrope text-base pt-2 md:pt-5 font-normal text-base leading-6 text-gray-500 hover:text-black block mb-6">
                {subscription.title.split('\n').map((line, index) => (
                  <p key={index} className='text-xs sm:text-base md:text-sm lg:text-lg mt-6 flex items-center'>
                    <FontAwesomeIcon
                      icon={solid('circle-check')}
                      style={{ color: `${getSubscriptionColor(subscription.id)}`, alignSelf: 'center' }}
                      className='mr-2'
                    />
                    {line}
                  </p>
                ))}
              </div>
              <PlanChangeModal
                isOpen={subscriptionsModalInfo[subscription.id]?.isOpen || false}
                onClose={() => setSubscriptionsModalInfo(prevState => ({ ...prevState, [subscription.id]: { ...prevState[subscription.id], isOpen: false } }))}
                onConfirm={() => {
                  setSubscriptionsModalInfo(prevState => ({ ...prevState, [subscription.id]: { ...prevState[subscription.id], isOpen: false } }))
                  subscriptionPlanChange(subscription.id)
                }}
                chosenPlan={subscriptionsModalInfo[subscription.id]?.chosenPlan || ''}
                chosenPrice={subscriptionsModalInfo[subscription.id]?.chosenPrice || ''}
              />
            </>
            )
          : (renderMasterPlan())}
      </SwiperSlide>
    ))
  }

  function renderHomeMobilePlans () {
    // @ts-expect-error it was here
    return renderMobile(subscriptionPlansHome)
  }

  function renderMobilePlans () {
    return renderMobile(subscriptionPlans)
  }

  return (
    <div className='bg-white p-8 mb-12 md:w-full mx-5 md:mx-0 flex flex-col gap-3 justify-center rounded-3xl'>
      <div className="flex w-full">
        <BackButton
          backPage={backPage}
          handleBackPage={handleBackPage}
          text=""
          className="quiz_back_button2 block "
        />
      </div>
      <div className='flex flex-col justify-center items-center md:justify-start md:items-start gap-3'>
        <Header className='font-normal font-medium text-center md:text-left text-2xl md:text-4xl leading-4 text-gray-900' text='Start Growing Online!' />
        <Header className=' text-sm md:text-lg font-normal text-center mb-8 md:text-left break-words text-gray-500' text="Choose one of our Brand Fitness Plans designed especially to meet your needs." />
        {
          isScreenWidthAbove768
            ? (
            <div className="flex flex-row font-normal text-base p-0 leading-6 text-gray-500 block gap-8 mb-4 my-12 md:my-0 mx-auto relative">
              {homeSubscription ? renderSubscriptionHomePlans() : renderSubscriptionPlans() }
            </div>
              )
            : (
                <>
                  <div className="flex items-center justify-center">
                    Swipe for more plans <FontAwesomeIcon icon={solid('arrow-right')} className='ml-3'/>
                  </div>
                  <Swiper
                    pagination={{
                      type: 'progressbar'
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="mySwiper w-full"
                  >
                    {homeSubscription ? renderHomeMobilePlans() : renderMobilePlans() }
                  </Swiper>
                </>
              )}
      </div>
    </div>
  )
}
