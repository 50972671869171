import QuizGoalsPageInfo from '../../molecules/QuizGoalsPageInfo/QuizGoalsPageInfo'
import QuizGoalsPageForm from '../../molecules/QuizGoalsPageForm/QuizGoalsPageForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizGoalsPage ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <QuizGoalsPageInfo className='flex flex-col justify-center items-center relative visible_1' />
      <QuizGoalsPageForm handleShowPage={handleShowPage} className='w-auto md:w-full lg:w-[582px] flex flex-wrap justify-center items-center mt-5 relative visible_1' />
    </div>
  )
}
