import React, { useState } from 'react'
import Header from '../../atoms/Heading/Heading'
import phoneImg from '../../../img/phone.svg'
import addressImg from '../../../img/address.svg'
import emailImg from '../../../img/email.svg'

interface Props {
  className?: string
}

export default function ContactUsPageInfo ({ className }: Props) {
  const [showPhone, setShowPhone] = useState(false)
  const [showEmail, setShowEmail] = useState(false)
  const [showAddress, setShowAddress] = useState(false)

  const togglePhone = () => {
    setShowPhone(!showPhone)
    setShowEmail(false)
    setShowAddress(false)
  }

  const toggleEmail = () => {
    setShowEmail(!showEmail)
    setShowPhone(false)
    setShowAddress(false)
  }

  const toggleAddress = () => {
    setShowAddress(!showAddress)
    setShowPhone(false)
    setShowEmail(false)
  }

  // Check if the device is mobile based on screen width
  const isMobile = window.innerWidth <= 768

  return (
    <div className={`flex flex-col justify-center items-center md:justify-start md:items-start w-full gap-3 ${className}`}>
      <Header className='font-normal font-semibold text-3xl leading-7 text-black block' text='Contact Us' />
      <Header className='text-lg font-normal text-center md:text-left break-words text-gray-500' text="Happy to assist with any questions 🩷" />
      <div className='contact-icons-wrapper flex justify-between md:w-full gap-3 md:gap-8 mt-5'>
        <div className={`contact-card bg-gray-100 rounded-md flex flex-col items-center md:items-start basis-1/3 h-28 md:h-40 border-none pb-0 md:p-8 mr-0.5 md:mr-0 ${!isMobile && 'cursor-pointer'}`} onClick={isMobile ? togglePhone : undefined}>
          <img src={phoneImg} alt="Phone" />
          <p className="font-normal text-base leading-5 text-gray-500 pt-5">Phone</p>
          {(showPhone || !isMobile) && <p className="font-normal text-sm leading-2 text-black pt-2">+972529771591</p>}
        </div>
        <div className={`contact-card bg-gray-100 rounded-md flex flex-col items-center md:items-start basis-1/3 h-28 md:h-40 border-none pb-0 md:p-8 mr-0.5 md:mr-0 ${!isMobile && 'cursor-pointer'}`} onClick={isMobile ? toggleEmail : undefined}>
          <img src={emailImg} alt="E-Mail" />
          <p className="font-normal flex items-center md:items-start text-base leading-5 text-gray-500 pt-5">Email</p>
          {(showEmail || !isMobile) && (
            <a href='mailto:contact@mybranda.com'>
              <p className="font-normal text-sm leading-2 text-black pt-2">contact@mybranda.com</p>
            </a>
          )}
        </div>
        <div className={`contact-card bg-gray-100 rounded-md flex flex-col items-center md:items-start basis-1/3 h-28 md:h-40 border-none pb-0 md:p-8 mr-0.5  md:mr-0 ${!isMobile && 'cursor-pointer'}`} onClick={isMobile ? toggleAddress : undefined}>
          <img src={addressImg} alt="Address" />
          <p className="font-normal text-base leading-5 text-gray-500 pt-5">Address</p>
          {(showAddress || !isMobile) && <p className="font-normal text-sm text-center md:text-left leading-2 text-black pt-2">Brooklyn NY</p>}
        </div>
      </div>
    </div>
  )
}
