/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useState, useEffect } from 'react'
import { Instagram, Facebook, LinkedIn } from '@mui/icons-material/'
import FormControlLabel from '@mui/material/FormControlLabel'
import Checkbox from '@mui/material/Checkbox'
import { useAppDispatch, useAppSelector } from 'app/hooks'
import { setFacebook, setFacebookPro, setInstagram, setInstagramPro, setLinkedin } from 'app/quiz/userSocialMedia'

interface Platform {
  name: string
  icon: JSX.Element
}

interface Props {
  isOpen: boolean
  onClose: () => void
  onConfirm: () => void
}

export default function SocialMediaPlatformPopUp ({ isOpen, onClose, onConfirm }: Props) {
  const dispatch = useAppDispatch()
  const facebookChecked = useAppSelector((state) => state.userSocialMediaReducer.facebook)
  const facebookProChecked = useAppSelector((state) => state.userSocialMediaReducer.facebookpro)
  const instagramChecked = useAppSelector((state) => state.userSocialMediaReducer.instagram)
  const instagramProChecked = useAppSelector((state) => state.userSocialMediaReducer.instagrampro)
  const linkedinChecked = useAppSelector((state) => state.userSocialMediaReducer.linkedin)
  const [isDisabled, setIsDisabled] = useState(true)

  const platforms: Platform[] = [
    { name: 'Instagram', icon: <Instagram style={{ fontSize: '36px' }} /> },
    { name: 'InstagramPro', icon: <Instagram style={{ fontSize: '36px' }} /> },
    { name: 'Facebook', icon: <Facebook style={{ fontSize: '36px' }} /> },
    { name: 'FacebookPro', icon: <Facebook style={{ fontSize: '36px' }} /> },
    { name: 'LinkedIn', icon: <LinkedIn style={{ fontSize: '36px' }} /> }
  ]

  useEffect(() => {
    const checkedCount =
    ((facebookChecked || facebookProChecked) ? 1 : 0) +
    ((instagramChecked || instagramProChecked) ? 1 : 0) +
    (linkedinChecked ? 1 : 0)

    setIsDisabled(checkedCount < 2)
  }, [facebookChecked, facebookProChecked, instagramChecked, instagramProChecked, linkedinChecked])

  useEffect(() => {
    setIsDisabled(true)
  }, [])

  const handlePlatformChange = (platform: string) => {
    switch (platform) {
      case 'Facebook':
        dispatch(setFacebook(true))
        break
      case 'FacebookPro':
        dispatch(setFacebookPro(!facebookProChecked))
        break
      case 'Instagram':
        dispatch(setInstagram(!instagramChecked))
        break
      case 'InstagramPro':
        dispatch(setInstagramPro(!instagramProChecked))
        break
      case 'LinkedIn':
        dispatch(setLinkedin(!linkedinChecked))
        break
      default:
        break
    }
  }

  return (
    <div className={`flex fixed top-0 right-0 left-0 z-50 backdrop-blur-sm justify-center items-center w-full md:inset-0 h-full ${isOpen ? '' : 'hidden'}`}>
      <div className="absolute w-full h-full bg-gray-800 opacity-50"></div>
      <div className="relative">
        <div className="branda-score w-[350px] md:w-[600px] h-72 rounded-3xl shadow dark:bg-gray-700  mt-2 pt-10">
          <div className="flex flex-col items-center justify-center md:mt-2 p-2 md:p-3 rounded-t dark:border-gray-600 gap-4">
            <div className='text-center'>
              <p className="font-semibold md:font-medium text-base md:text-2xl text-black p-0 mb-0 flex justify-center">
                Select the platforms you will post from
              </p>
              <p className='mt-2 text-xs hidden md:inline-block md:text-sm'>Choose at least 2 platforms, your <strong>Personal Facebook</strong>  account is mandatory!</p>
              <p className='mt-2 text-xs md:hidden md:text-sm'>Choose at least 2 platforms.<br/> Your <strong>Personal Facebook</strong>  account is mandatory!</p>
            </div>
            <div className="flex flex-row justify-center items-center ml-6">
              {platforms.map((platform) => (
                <FormControlLabel
                  key={platform.name}
                  control={
                    <div className="flex flex-col items-center">
                      <div className="relative flex justify-center">
                        {(platform.name === 'InstagramPro' || platform.name === 'FacebookPro') && (
                          <span className="absolute small-text font-medium font-black text-purple-800 -bottom-1">Business</span>
                        )}
                        <Checkbox
                          checked={
                            (platform.name === 'Facebook' && facebookChecked) ||
                            (platform.name === 'FacebookPro' && facebookProChecked) ||
                            (platform.name === 'Instagram' && instagramChecked) ||
                            (platform.name === 'InstagramPro' && instagramProChecked) ||
                            (platform.name === 'LinkedIn' && linkedinChecked)
                          }
                          onChange={() => {
                            handlePlatformChange(platform.name)
                          }}
                          icon={platform.icon}
                          checkedIcon={React.cloneElement(platform.icon, {
                            style: {
                              color:
                                platform.name === 'Instagram' || platform.name === 'InstagramPro'
                                  ? 'rgba(206, 53, 134, 1)'
                                  : platform.name === 'LinkedIn'
                                    ? '#0077b5'
                                    : '#1976D2',
                              fontSize: platform.name === 'Twitter' ? '28px' : '36px'
                            }
                          })}
                        />
                      </div>
                    </div>
                  }
                  label={null}
                />
              ))}
            </div>
          </div>
          <div className="p-2 md:px-5 pt-6 md:pt-3 space-y-4">
            <div className="flex justify-center items-center">
              <button
                className={`px-10 py-3 w-48 text-sm md:text-xl font-semibold text-white rounded-2xl 
              ${isDisabled ? 'bg-gray-300 cursor-not-allowed' : 'bg-purple-600'}`}
                onClick={onConfirm}
                disabled={isDisabled}
              >
                Start
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
