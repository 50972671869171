import NavBar from 'components/organisms/NavBar/NavBar'
import Subscription from '../components/templates/Home/Subscription'
import Footer from 'components/organisms/Footer/Footer'

export default function HomeSubscriptionPage () {
  return (
    <>
      <NavBar className='home_navbar relative flex flex-row justify-between items-center p-5 pt-3 h-24 align-center -mb-5 sm:-mb-2' />
      <div className='container mx-auto p-5'>
        <Subscription />
      </div>
      <Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 p-3 pt-8 md:p-3 -mt-2 bg-white' />
    </>
  )
}
