/*eslint-disable*/
import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import Heading from 'components/atoms/Heading/Heading'
import { useAppSelector } from 'app/hooks'
import { type Tasks } from 'app/quiz/quizSlice'
import Icon from 'components/atoms/Icon/Icon'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { users } from 'app/services/Api'
import { toast } from 'react-toastify'
import instagram from '../../../img/instagram.png'
import facebook from '../../../img/facebook.png'
import linkedin from '../../../img/linkedin_logo.png'
import DeleteConfirmationWeeklyGoals from 'components/molecules/DeleteConfirmationWeeklyGoals/DeleteConfirmationWeeklyGoals'
import ConfirmationCompletedWeeklyGoals from 'components/molecules/ConfirmationCompletedWeeklyGoals/ConfirmationCompletedWeeklyGoals'
import dayjs from 'dayjs'
import EndTrialModal from 'components/molecules/ProfileForm/Modals/EndTrialModal'

export default function AllWeeklyGoalsContent() {
  
  // Redux and Navigation
  const navigate = useNavigate()
  const currentUser = useAppSelector((state) => state.quizReducer.currentUser)
  
  // States
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false)
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [taskId, setTaskId] = useState<number>()
  const [totalTasksCount, setTotalTasksCount] = useState<number>(0)
  const [totalTasksCompleted, setTotalTasksCompleted] = useState<number>(0)
  const [groupedTasks, setGroupedTasks] = useState<Record<string, Tasks[]>>({})
  const [totalTasksCompletedOrNot, setTotalTasksCompletedOrNot] = useState<number>(0)
  const [taskCompletedAnimation, setTaskCompletedAnimation] = useState<boolean>(false)
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const [startOfWeek, setStartOfWeek] = useState<string>("")
  const [endOfWeek, setEndOfWeek] = useState<string>("");

  // Variables
  const platformImageMap: Record<string, string> = {
    Facebook: facebook,
    LinkedIn: linkedin,
    Instagram: instagram
  }

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useLayoutEffect(() => {
    window.scrollTo(0, 0)

    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])

  useEffect(() => {
    fetchTasks()
  }, [currentUser?.id])

  const fetchTasks = async () => {
    try {
      if (currentUser?.id) {
        let totalTasks: Tasks[] = []
        const response = await users.getUserTasks(true)
        totalTasks = response

        if (totalTasks) {
          const groupedTask = totalTasks.reduce<Record<string, Tasks[]>>((acc, task) => {
            const platform = task.task.platform
            if (!acc[platform]) {
              acc[platform] = []
            }
            acc[platform].push(task)
            return acc
          }, {})

          const customOrder = ['Facebook', 'Instagram', 'LinkedIn']
          const sortedKeys = Object.keys(groupedTask).sort((a, b) => {
            return customOrder.indexOf(a) - customOrder.indexOf(b)
          })

          const sortedGroupedTasks: Record<string, Tasks[]> = {}
          sortedKeys.forEach((key) => {
            sortedGroupedTasks[key] = groupedTask[key]
          })
          
          // Sort tasks within each group by id ascending
          for (const key in sortedGroupedTasks) {
            if (sortedGroupedTasks.hasOwnProperty(key)) {
                sortedGroupedTasks[key].sort((a, b) => a.id - b.id);
            }
          }
          const totalTasksCompleted = totalTasks.filter((key) => key.completed === true).length
          const totalTasksCompletedOrNot = totalTasks.filter((key) => key.completed !== null).length

          const currentDate = dayjs()
          const startOfWeek = currentDate.startOf('week').add(1, 'day').format('DD/MM/YYYY')
          const endOfWeek = currentDate.endOf('week').add(1, 'day').format('DD/MM/YYYY')
          console.log(totalTasks)

          if(totalTasks.length > 0){

            const firstTask = totalTasks[0];
            const currentDate = dayjs(firstTask.week_date)
            setStartOfWeek(currentDate.format("DD/MM/YYYY"))
            setEndOfWeek(currentDate.add(6, 'day').format("DD/MM/YYYY"))

          } else {
            const currentDate = dayjs()
            setStartOfWeek(currentDate.startOf('week').add(1, 'day').format('DD/MM/YYYY'))
            setEndOfWeek(currentDate.endOf('week').add(1, 'day').format('DD/MM/YYYY'))

          }
          setGroupedTasks(sortedGroupedTasks)
          setTotalTasksCount(response.length)
          setTotalTasksCompleted(totalTasksCompleted)
          setTotalTasksCompletedOrNot(totalTasksCompletedOrNot)
          setIsLoading(false)
        }
      }
    } catch (err) {
      console.log(err)
    }
  }

  const handleTaskCompleted = async (
    taskId: number,
    completed: boolean | null,
    postponed: boolean | undefined
  ) => {
    try {
      await users.updateUserTask(taskId, !!completed, undefined, undefined, postponed)
      const message = completed ? 'Task is completed!' : 'Task is uncompleted!'
      toast.success(message)
    } catch (err) {
      console.log(err)
    }

    setIsDeleteModalOpen(false)
    setIsConfirmationModalOpen(false)

    fetchTasks()
  }

  const handleDeleteTask = async () => {
    try {
      await handleTaskCompleted(taskId!, false, undefined)
      return Promise.resolve()
    } catch (error) {
      return Promise.reject(error)
    }
  }


  const triggerAnimation = () => {
    setTaskCompletedAnimation(true)
    setTimeout(() => {
      setTaskCompletedAnimation(false)
    }, 1000)
  }

  
  const handleConfirmationCompleted = async () => {
    await handleTaskCompleted(taskId!, true, undefined)
    triggerAnimation()
  }


  const handlePostponeTask = async () => {
    await handleTaskCompleted(taskId!, null, true)
  }

  const handleCloseConfirmation = async () => {
    setIsConfirmationModalOpen(false)
    setIsDeleteModalOpen(false)
  }
  
  const handleCloseModal = async () => {
    setIsConfirmationModalOpen(false)
    setIsDeleteModalOpen(false)
  }

  const handleTaskRemoveClick = (taskId: number) => {
    setTaskId(taskId)
    setIsDeleteModalOpen(true)
  }

  const handleTaskConfirmClick = (taskId: number) => {
    setTaskId(taskId)
    setIsConfirmationModalOpen(true)
  }

  const isTrialModalShown = (): boolean => {
    const subscription = currentUser?.subscription[0]
    const today = new Date()
    const todayDate = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ('0' + today.getDate()).slice(-2)

    return (subscription !== undefined && subscription.valid_until) < todayDate
  }

  const handleTaskClick = (taskId: number) => {
    const url = `/weekly-goals/${taskId}`

    if(isScreenWidthAbove768) {
      window.open(url, '_blank')
    } else {
      navigate(`/weekly-goals/${taskId}`)
    }
  }

  return (
    <>
      <div className="relative md:mx-auto w-full visible_1">
        <div className="flex items-center justify-between p-2 md:p-3 rounded-t dark:border-gray-600 w-full">
          <div className="flex flex-col gap-2 w-full">
            <h1 className='flex justify-start font-medium tracking-wide text-xl md:text-4xl text-black block p-0'>Your weekly goals</h1>
            <div className="flex justify-between w-full">
              <Heading
                className="text-xs mr-10 md:text-base leading-relaxed text-gray-400 dark:text-gray-400"
                text="Click on the task to get instructions and see your personalized content suggestions. Then mark ✅ ❌ upon completion."
              />
              <p><span className='italic'>{startOfWeek}</span> <span className='hidden md:inline-block'>-</span> <span className='italic'>{endOfWeek}</span></p>
            </div>
          </div>
        </div>
        <div className="px-2 py-1 md:px-3 md:py-2 space-y-4">
          {totalTasksCount !== totalTasksCompletedOrNot && (
            <>
              <Heading
                className="font-normal font-base text-lg leading-7 mt-7 mb-10 text-center text-start text-black block"
                text="Manage your weekly tasks and track your progress!"
              />
          
              {Object.keys(groupedTasks).map((platform) => (
                <div key={platform}>
                  <div className="flex items-center mb-3">
                    <img src={platformImageMap[platform]} alt={platform} width={35} />
                    <span>
                      <h3 className="ml-2 md:text-xl font-medium">
                        {platform}
                      </h3>
                    </span>
                  </div>
                  <ul className="list-none list-disc flex flex-col text-gray-800 md:pl-14 gap-3">
                    {groupedTasks[platform].map((task) => (
                      <li key={task.task.id}>
                        <div className="flex items-center justify-between">
                          <div className='flex justify-between align-center items-center w-full '>
                            <p
                              onClick={task.completed === null ? () => { handleTaskClick(task.task.id) } : undefined}
                              className={`flex justify-between w-full hover:cursor-pointer hover:bg-gray-200 rounded-2xl p-3 mr-2 border border-gray-300 p-2 rounded-md ${task.completed === true ? 'line-through bg-gray-300' : ''} ${task.completed === false ? 'line-through bg-gray-300' : ''}`}
                            >
                              {task.task.task_title}
                            </p>
    
                            <span className={`text-sm mr-4 ${task.completed === true ? 'text-green-600' : task.completed === false ? 'text-red-600' : ''}`}>
                              {task.completed === true ? 'Completed' : task.completed === false ? 'Abandoned' : ''}
                            </span>
                          </div>
                          <div className="flex items-center space-x-2">
                            <Icon
                              className={`text-green-500 border border-green-500 ${task.completed === true ? 'bg-gray-200' : ''} p-1 rounded-lg cursor-pointer`}
                              icon={solid('check')}
                              handleOnClick={task.completed === null ? () => { handleTaskConfirmClick(task.task.id) } : undefined}
                            />
                            <Icon
                              className={`text-red-500 border border-red-500 ${task.completed === false ? 'bg-gray-200' : ''} p-1 rounded-lg cursor-pointer`}
                              icon={solid('xmark')}
                              handleOnClick={task.completed === null ? () => { handleTaskRemoveClick(task.task.id) } : undefined}
                            />
                          </div>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}  
            </>
          )}

          <div className="flex justify-center mt-5 md:mt-10">
            {!isLoading && totalTasksCompleted === totalTasksCount ? (
              <div className='rounded-lg border-2 border-violet-200 p-2 md:p-4 hover:bg-purple-100 text-center text-base md:text-lg'>
                <p>You're all finished for this week 💪🏻 🔥</p>
                <p>Time for your winner's coffee chill ☕🏆</p>
              </div>
            ) : !isLoading && totalTasksCount === totalTasksCompletedOrNot && (
              <div className='rounded-lg border-2 border-violet-200 p-2 md:p-4 hover:bg-purple-100 text-center text-base md:text-lg'>
                <p>You're all caught up for this week 👍🏻</p>
                <p>We'll notify you when your new tasks are here 📦</p>
              </div>
            )}
          </div>
            
          {!isLoading && (
            <div className="flex justify-center mt-5">
              <p>Completed {totalTasksCompleted}/{totalTasksCount} tasks</p>
            </div>
          )}

        </div>
      </div>
      <DeleteConfirmationWeeklyGoals isOpen={isDeleteModalOpen} onCloseIcon={handleCloseModal} onClose={handlePostponeTask} onConfirm={handleDeleteTask} />
      <ConfirmationCompletedWeeklyGoals isOpen={isConfirmationModalOpen} onCloseIcon={handleCloseModal} onClose={handleCloseConfirmation} onConfirm={handleConfirmationCompleted} />
      {taskCompletedAnimation && (
      <div className="thumbs-up-animation">
       <p className="text-xs bg-violet-100 p-2 rounded-lg">Task points added 👍</p>
      </div>
    )}

      <EndTrialModal isOpen={isTrialModalShown()}  onCloseModal={null} />
    </>
  )
}
