/* eslint-disable react/no-unescaped-entities */
import Footer from 'components/organisms/Footer/Footer'
import NavBar from 'components/organisms/NavBar/NavBar'
import React from 'react'

export default function TermsOfServicePage () {
  return (
    <>
    <NavBar className='home_navbar relative flex flex-row justify-between items-center p-5 pt-3 h-24 align-center -mb-5 sm:-mb-2' />
    <div className='bg-white'>
    <div className='bg-slate-100 mx-auto p-8 md:p-28 mt-4'>
        <h1 className='main_header_home font-extrabold text-2xl md:text-6xl'>
            Terms of Service
        </h1>
        <p className='mt-8 text-base md:text-2xl'>
            Review MyBranda's terms of service. Understand the guidelines and policies that govern your use of our branding services.
        </p>
    </div>
    <div className='container mx-auto mt-0 md:mt-14 p-9 text-left md:text-justify'>
        <h1 className='text-2xl md:text-6xl font-bold'>Legal</h1>
        <p className='mt-4 md:mt-12 text-sm md:text-xl'>
            MyBranda Software Ltd. (a company under incorporation) ("Company", “MyBranda”, or “us”, “our”, “we”) provides a platform for managing and analyzing your online
            branding presence (together, the “Platform”) by means of a website located at www.mybranda.com (the “Site”). These Terms of Service (“Terms”) govern your access
            and use of the Platform and/or the Site. “You”means any person who uses the Platform to as well as any visitor to the Site.
        </p>
         <p className='mt-4 text-sm md:text-xl'>
            Please read these Terms carefully. These Terms govern your access and use of the Platform and/or Site and any proprietary technology of MyBranda
            incorporated therein. By using the Platform and/or visiting the Site you signify your assent to both these Terms and our Privacy Policy, which is available
            at https://www.mybranda.com/privacy
         </p>
        <p className='mt-4 text-sm md:text-xl'>
            Changes may be made to these Terms from time to time. Your continued use of the Platform and/or Site will be deemed acceptance to any such amended or
            updated terms. If you do not agree to any of these Terms, please do not check “I accept” when signing up to the Platform and do not use the Platform and/or Site.
            If you accept these terms on behalf of an organization, you hereby represent that you have the authority to do so. Currently, MyBranda makes certain versions of the
            Platform available free-of-charge to you, but MyBranda may choose at any time to charge for your future use of the Platform and/or Site and/or change the pricing anytime.
         </p>

    <ol className=' list-decimal mt-10 text-sm md:text-lg mb-2 md:mb-8'>
        <li>
            <p>
                <span className='font-bold'>Platform. </span>
                Subject to the terms and conditions of these Terms, MyBranda shall provide you with access to the Platform.
                You may use the Platform on a non-exclusive basis solely for your own personal purposes.
                You shall not be entitled to any other software (including any other executable or source code) from MyBranda.
                No licenses or rights are granted herein by estoppel orby implication. You represent that you have all necessary authority to enter into these
                Terms and that the execution of these Terms and the receipt of the Platform will not conflict with any legal, regulatory or contractual obligations.
                You represent that you have the right to allow MyBranda to access your social media platforms and use them for the purposes of providing you the services
                described hereunder.
            </p>
        </li>
        <li className="mt-8">
            <p>
                <span className='font-bold'> Restrictions. </span>
                Except as set forth expressly herein, you shall not, and shall not permit any third party, to (a) reverse engineer or attempt to find the underlying code of,
                the Platform and/or Site; (b) modify the Platform and/or Site, (c) sublicense, sell, distribute or provide the Platform and/or Site to any third party, or
                (d)bypass any security measure or access control measure of the Platform and/orSite. To the extent any of the restrictions set forth in this Section are not
                enforceable under applicable law, you shall inform MyBranda in writing in each instance prior to engaging in the activities set forth above.
            </p>
        </li>
        <li className="mt-8">
            <p>
                <span className='font-bold'> Accounts. </span>
                You may register to use the Platform by providing your name, email, employment status, gender, phone number, and any additional required details and/or by
                logging in through your personal existing account on a different platform such as (but not limited to) Google/Facebook(Meta)/LinkedIn, Twitter or other social
                media/ digital data platforms (such as, but not limited to, Apple ID) (“PersonalExisting Account”).
                We may change the method of registration at our discretion. By registering through aPersonal Existing Account, you represent and warrant that the Personal
                ExistingAccount is yours and you have full rights to provide us with the information in this account. We may refuse to allow any user to open an account for
                any reason at our sole discretion. You agree to supply accurate and complete information to us when creating your account and when using the Platform, as well
                as to update such information promptly after any change. You will be responsible for any inaccuracies in the information you provide to us, or for your failure
                to keep such information up-to-date.
                Do not share your account or login information with any third party, nor let any third party access your account. You are fully and solely responsible for
                maintaining the confidentiality of the login information for your account and for the security of your computer system, mobile device and all activity on your
                account, even if such activities were not committed by you. We may terminate your account if you let someone use your account inappropriately or if you or
                anyone using your account violates these Terms.You agree to immediately notify us of any unauthorized use of your account. We will not be liable for any losses
                or damage arising from unauthorized use of the Platform and/or Site, and you agree to indemnify and hold us harmless for any improper or illegal use of the
                Platform and/or Site, and any charges and taxes incurred, unless you have notified us via email at dana@mybranda.com.
            </p>
        </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Termination of Account. </span>
                    We may for any reason, in our sole discretion and without notifying you, terminate your account. Grounds for such termination may include (i) extended periods
                    of inactivity, (ii) violation of the letter or spirit of these Terms, (iii) fraudulent, harassing or abusive behavior or (iv)behavior that is harmful to other
                    users, third parties, the community ethos of ourPlatform and/or Site or our business interests. In the event that we terminate your account, you may not
                    register for the Platform and/or Site again without our express permission. If you believe that any action has been taken against your account in error,
                    please contact us at contact@mybranda.com.
                    In addition, if we believe, in our sole discretion ,that a violation of these Terms or any illegal or inappropriate behavior has occurred, we may take any other
                    corrective action we consider appropriate. We will investigate suspected violations of these Terms or illegal and inappropriate behavior through the Platform
                    and/or Site. Please note that we will fully cooperate with any law enforcement investigation or court order ordering us or directing us to disclose the identity,
                    behavior or activities of anyone believed to have violated these Terms or to have engaged in illegal behavior.
                    You may request termination of your account at anytime and for any reason by sending an email to dana@mybranda.com. Any suspension or termination of your account
                    shall not affect your obligations to us under these Terms (including but not limited to ownership, indemnification, any representations and warranties made by
                    you, and limitation of liability),including all those obligations, which by their sense and context are intended to survive the suspension or termination of
                    your account.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Data; Privacy. </span>
                    In order to provide the Platform and/or Site MyBranda collects certain data provided by you including any data included in your social media platforms (“Data”),
                    in accordance with the terms of our PrivacyPolicy which is available at https://www.mybranda.com/privacy , as amended from time to time.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> IntellectualProperty. </span>
                    MyBranda has all right, title and interest in the Platform and/or Site including all enhancements, improvements and modifications thereof(“MyBranda Property”).
                    If you provide MyBranda with any feedback regarding the MyBranda Property, MyBranda may use all such feedback without restriction and shall not be subject to
                    any non-disclosure or non-use obligations in respect of such feedback.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Confidentiality. </span>
                    Either party (a “Disclosing Party”) may disclose to the other party (a “Receiving Party”) certain confidential information regarding its technology and business
                    (“ConfidentialInformation”).Receiving Party agrees to keep confidential and not disclose or use anyConfidential Information except to support its provision or
                    use of the Platform.Confidential Information shall not include information that Receiving Party can show (a) was already lawfully known to or independently
                    developed by ReceivingParty without access to or use of Confidential Information, (b) was received byReceiving Party from any third party without restrictions,
                    (c) is publicly and generally available, free of confidentiality restrictions; or (d) is required to be disclosed by law, provided that Receiving Party provides
                    Disclosing Party with prompt notice of such requirement and cooperate in order to minimize such requirement. Receiving Party shall restrict disclosure of
                    ConfidentialInformation to those of its employees with a reasonable need to know such information and which are bound by written confidentiality obligations no
                    less restrictive than those set out herein. All pricing information herein and all non-public information in respect of the Platform shall be deemed theConfidential Information of MyBranda.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'>Warranties;Disclaimer. </span>
                    You warrant that you are authorized to provide MyBranda with access to your social media platforms as well as to the Data for purposes of receiving the services
                    on the Platform. ANY ACTIONS YOU TAKE BASED WHILE USING THE PLATFORM AND/OR SITE ARE TAKEN AT YOUR SOLE RISK AND WE WILL NOT ACCEPT ANY LIABILITY IN RESPECT
                    THEREOF. YOU SHOULD ALWAYS CHECK ANY INFORMATION PROVIDED THROUGH THE PLATFORM AND/OR SITE TO ENSURE ITS ACCURACY. We reserve the right to correct any errors,
                    inaccuracies or omissions and to change or update information or content at any time without prior notice (including after you have submitted an order).
                    The Platform, Site and content are provided on an AS IS and AS AVAILABLE basis without warranties of any kind from MyBranda. MYBRANDA EXPRESSLY DISCLAIMS
                    ALL WARRANTIES OF ANY KIND, EXPRESS, IMPLIED OR STATUTORY,RELATING TO THE PLATFORM, SITE AND/OR CONTENT MADE AVAILABLE THROUGH THE SAME,INCLUDING WITHOUT
                    LIMITATION THE WARRANTIES OF TITLE, MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. MYBRANDA DISCLAIMS ANY WARRANTIES, EXPRESS OR
                    IMPLIED, (I) REGARDING THE SECURITY, ACCURACY,RELIABILITY, TIMELINESS AND PERFORMANCE OF THE PLATFORM AND/OR SITE; OR (II) THAT THE PLATFORM AND/OR SITE
                    WILL BE ERROR-FREE OR THAT ANY ERRORS WILL BE CORRECTED; OR (III) REGARDING THE PERFORMANCE OF OR ACCURACY, QUALITY,CURRENCY, COMPLETENESS OR USEFULNESS
                    OF ANY INFORMATION PROVIDED BY THE PLATFORM AND/OR SITE.
                    WE DISCLAIM ALL LIABILITY, REGARDLESS OF THE FORM OF ACTION, FOR THE ACTS OR OMISSIONS OF OTHER USERS (INCLUDING UNAUTHORIZED USERS), WHETHER SUCH ACTS OR OMISSIONS
                    OCCUR DURING THE USE OF THE PLATFORM AND/OR SITE OR OTHERWISE.
                    NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,OBTAINED BY YOU FROM MYBRANDA, SHALL CREATE ANY WARRANTY NOT EXPRESSLY STATED IN THESE TERMS. IF YOU CHOOSE T
                    RELY ON SUCH INFORMATION, YOU DO SO SOLELY ATYOUR OWN RISK. SOME STATES OR JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN WARRANTIES. ACCORDINGLY, SOME OF
                    THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Indemnification. </span>
                    You shall defend, indemnify and hold MyBranda (and its affiliates, officers, directors and employees) harmless from and against any and all damages, costs,
                    losses, liabilities or expenses (including court costs and attorneys’ fees) which MyBranda may suffer or incur in connection with any actual claim, demand,
                    action or other proceeding by any third party arising from (a) the provision of any Data or content not in compliance with applicable law; (b) breach of these
                    Terms or of any law or regulation; and/or (c)infringement of any right of any third party.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Limitation of Liability. </span>
                    In no event shall MyBranda (or its directors, officers, affiliates, agents or employees) have any liability for any consequential, indirect, special or punitive
                    damages, arising out of or relating to the Site or Platform. The entire liability of MyBranda (or its directors, officers, affiliates, agents or employees)
                    hereunder shall not exceed the total amount you have actually paid to MyBranda in respect of the Platform and/or Site in the 12 months prior to the applicable
                    claim. As such, if you have not made any payment to MyBranda for the use of the Site and/or Platform, MyBranda will have no liability towards you.
                    In addition to the foregoing, we assume no responsibility for any error, omission, interruption, deletion ,defect, delay in operation or transmission or
                    communications line failure. We are not responsible for any problems or technical malfunction of any telephone or cellular phone network or lines, computer
                    online systems, servers or providers, computer equipment, software, failure of any email due to technical problems or traffic congestion on the Internet
                    or on the , including any injury or damage to users or to any person's mobile device or computer related to or resulting from participation or use of the
                    Platform and/or Site. UNDER NO CIRCUMSTANCES SHALL WE BE RESPONSIBLE FORANY LOSS OR DAMAGE, INCLUDING PERSONAL INJURY OR DEATH, RESULTING FROM USE OF THE
                    Platform and/or Site, WHETHER ONLINE OR OFFLINE WHILE USING THE Platform and/or Site.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Termination of Account. </span>
                    You agree that MyBranda may for any reason, in its sole discretion and without notice, terminate your account, and remove from the Platform any Data associated
                    with your account. Grounds for such termination may include (i)extended periods of inactivity, (ii) violation of the letter or spirit of theseTerms, (iii)
                    fraudulent, harassing or abusive behavior, or (iv) behavior that is harmful to other users, including vendors, other users or the business interests of MyBranda.
                    If your account is terminated, you may not rejoin MyBranda again without our express permission.
                    If MyBranda believes, in its sole discretion, that a violation of these Terms or any illegal or inappropriate behavior has occurred, we may take any other
                    corrective action we deem appropriate. We reserve the right to investigate suspected violations of theseTerms or illegal and inappropriate behavior on the
                    Platform. We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or behavior of anyone
                    believed to have violated these Terms or to have engaged in illegal behavior on the Platform.You may request termination of your MyBranda account at any time
                    and for any reason by sending an email to dana@mybranda.com. Any suspension, termination, or cancellation of your account or these Terms shall not affect your obligations to MyBranda under these Terms (including but not limited to intellectual property ownership, indemnification, and limitation of liability), which by their sense and context are intended to survive such suspension, termination, or cancellation.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Representations. </span>
                    As long as these Terms are in effect between MyBranda and you, you hereby represent and warrant to MyBranda as follows: (a) any and all Data and content which
                    will be provided to us in accordance with theseTerms has been lawfully obtained; (b) You have provided all necessary notices and/or obtained any and all
                    required consents from any third parties for collecting and processing Data (as required by applicable law); and (c) You shall maintain and be in compliance
                    with all applicable laws, regulations and guidelines relating to collecting or processing of Data and all provided content.
                </p>
            </li>
            <li className="mt-8">
                <p>
                    <span className='font-bold'> Miscellaneous. </span>
                    These Terms constitute the entire agreement between the parties regarding the subject matter hereof and supersedes any and all other agreements between the
                    parties regarding the subject matter hereof. Except as expressly set forth herein, these Terms may not be modified or amended except in a writing executed
                    by both parties. If any part of these Terms shall be invalid or unenforceable, such part shall be interpreted to give the maximum force possible to such terms
                    as possible under applicable law, and such invalidity or un enforceability shall not affect the validity or enforceability of any other part or provision of
                    these Terms. MyBranda may assign its rights or obligations under these Terms to an affiliated company or to a purchaser of all or substantially all of MyBranda’s assets or share capital, or to any company succeeding to MyBranda’s business. You shall not assign any of its rights or obligations hereunder without MyBranda’s prior written consent. Assignments in violation of the foregoing shall be void. The Agreement will be governed and interpreted in accordance with the laws of theState of Israel, without regard to its choice of law principles. All disputes arising out of the Agreement will be submitted exclusively to the jurisdiction of the courts in Tel-Aviv-Jaffa, Israel, and each party hereby irrevocably consents to the jurisdiction of such courts and hereby waives all objections thereto. Each party hereby irrevocably waives any and all right to trial by juryNotwithstanding the foregoing, either party may seek an injunction or other equitable relief in any court of competent jurisdiction in order to prevent a breach or threatened breach of these Terms.
                </p>
            </li>
        </ol>
        <h1 className='mt-10 text-xs md:text-base mb-0 md:mb-8'>Last updated: February 24</h1>
    </div>
</div>
<Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 p-3 pt-8 md:p-3 -mt-2 bg-white' />
</>
  )
}
