/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React from 'react'
import { Link as NewLink } from 'react-router-dom'

interface Props {
  linkTo: string
  name: string
  className: string
  title?: string
  tm?: boolean
  target?: string
  rel?: string
  routerLink?: boolean
  onClick?: () => void
}

export default function Link ({ linkTo, name, className, title, tm = false, target, rel, routerLink, onClick }: Props) {
  if (!routerLink) {
    return (
      <a title={title} href={linkTo} className={className} target={target} rel={rel} onClick={onClick}>
        <span>
          {name}
        </span>
        {tm &&
          <span className="text-sm">®</span>
        }
         {tm &&
            <span className="ml-auto">
              <span className='text-sm md:text-base ml-1'>
                beta
              </span>
            </span>
          }
      </a>
    )
  }

  return <NewLink to={linkTo} className={className} onClick={onClick}>{ name}</NewLink>
}
