/* eslint-disable @typescript-eslint/no-misused-promises */
import React, { useEffect, useState } from 'react'
import CustomLink from '../../atoms/Link/Link'
import { Link, useNavigate } from 'react-router-dom'
import Button from 'components/atoms/Button/Button'
import { useAppDispatch, useAppSelector } from 'components/../app/hooks'
import { users } from 'app/services/Api'
import { setCurrentUser } from 'app/quiz/quizSlice'
import { toast } from 'react-toastify'

interface Props {
  className: string
  titleClassName?: string
}

export default function NavBar ({ className, titleClassName }: Props) {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()
  const [isScreenWidthAbove768, setIsScreenWidthAbove768] = useState(false)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)

  const updateScreenWidth = () => {
    if (window.innerWidth > 768) {
      setIsScreenWidthAbove768(true)
    } else {
      setIsScreenWidthAbove768(false)
    }
  }

  useEffect(() => {
    updateScreenWidth()
    window.addEventListener('resize', updateScreenWidth)

    return () => {
      window.removeEventListener('resize', updateScreenWidth)
    }
  }, [])
  const handleLogOut = async () => {
    await users.logOut(true).then(response => {
      dispatch(setCurrentUser(null))
      navigate('/')
      toast.success('Successfully logged out!')
    })
      .catch(err => {
        console.log(err)
      })
  }
  return (
    <div className={className}>
      <CustomLink className={`my_branda_logo sm:ml-5 flex self-center font-medium text-2xl md:text-3xl pb-2 ${titleClassName}`} linkTo='/' name='myBranda' tm={true} />

      <div className='flex flex-row gap-2 md:gap-5'>

        {
          (currentUser == null)
            ? (
              <>
                <Link to="/sign-in">
                  <Button
                    text="Sign In"
                    className='sign_in_button border border-white text-xs !w-20 md:!w-44 md:text-base flex p-2.5 md:p-3 justify-center items-center gap-3 rounded-lg text-white'
                  />
                </Link>
                <Link to="/quiz">
                { isScreenWidthAbove768
                  ? <Button
                    text='Brand me Up!'
                    className='brand_me_up_button text-xs !w-20 md:!w-44 md:text-base flex p-1 md:p-3 justify-center items-center gap-3 rounded-lg text-white'
                  />
                  : <Button
                    text='Start'
                    className='brand_me_up_button text-xs !w-20 md:!w-44 md:text-base flex  p-2.5 md:p-3 justify-center items-center gap-3 rounded-lg text-white'
                  />
                }
                </Link>
              </>
              )
            : (
              <button onClick={handleLogOut} className='sign_in_button border border-white text-xs !w-20 md:!w-44 md:text-base flex p-3 justify-center items-center gap-3 rounded-lg text-white'>Log Out</button>
              )
        }

      </div>
    </div>
  )
}
