/* eslint-disable @typescript-eslint/quotes */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { RadialBarChart, PolarAngleAxis, RadialBar } from 'recharts'
import { useAppSelector } from 'app/hooks'
import dayjs from 'dayjs'

import Heading from 'components/atoms/Heading/Heading'
import Button from 'components/atoms/Button/Button'
import ConnectedNetworks from './ConnectedNetworks'
import Popup from './Popup/Popup'

import alertLogo from '../../../img/alertLogo.svg'
import instagram from '../../../img/instagram.png'
import linkedin from '../../../img/linkedin.png'
import facebook from '../../../img/facebook.png'
import { useMediaQuery } from 'react-responsive'
import CircularProgress from '@mui/material/CircularProgress/CircularProgress'

interface Props {
  className?: string
}

interface Social {
  name: string
  image: string
  altText: string
  question: string
  no_followers: number
}

const chartRadius = 180

export default function BrandaScoreAverage ({ className }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [selectedNetwork, setSelectedNetwork] = useState<any | null>(null)
  const [data, setData] = useState([
    { name: 'L1', value: 0 } // Initial value of the RadialBar
  ])
  const [isPopupOpen, setPopupOpen] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [scoreDifferential, setScoreDifferential] = useState(0)

  const isMobile = useMediaQuery({ query: '(max-width: 340px)' })
  const isSmallMobile = useMediaQuery({ query: '(max-width: 375px)' })
  const currentDate = dayjs().format('DD/MM/YYYY')

  const [connectedNetworksData, setConnectedNetworksData] = useState<Social[]>([])
  const handleConnectedNetworksSubmit = (network: Social) => {
    setConnectedNetworksData(prevData => [network, ...prevData])
  }

  const openPopup = (network: any) => {
    setSelectedNetwork(network)
    setPopupOpen(true)
  }

  const closePopup = () => {
    setPopupOpen(false)
  }

  const updateSocials = () => {
    if (currentUser?.social_media_scores) {
      const networks = ['Facebook', 'Instagram', 'Linkedin']
      return networks.filter(networkType => {
        const socialMedia = currentUser?.social_media_scores?.find(socialMedia => socialMedia.platform === networkType)
        return socialMedia !== undefined && socialMedia.no_followers !== 0 && socialMedia.no_followers !== null
      }).map(networkType => {
        const socialMedia = currentUser?.social_media_scores?.find(socialMedia => socialMedia.platform === networkType)

        let question = ''
        if (socialMedia?.platform === ' Facebook') {
          question = 'How many Followers/Friends do you have on FB account'
        } else {
          question = `How many Followers do you have on ${networkType}?`
        }

        const network: Social = {
          name: socialMedia?.platform ?? '',
          image: getImageForNetwork(socialMedia?.platform ?? ''),
          altText: socialMedia?.platform ?? '',
          question,
          no_followers: socialMedia?.no_followers ?? 0
        }

        return network
      })
        .sort((a, b) => {
          const order = ['facebook', 'instagram', 'linkedin']
          return order.indexOf(a.name.toLowerCase()) - order.indexOf(b.name.toLowerCase())
        })
        .flat()
    }

    return []
  }

  const getImageForNetwork = (networkType: string) => {
    switch (networkType) {
      case 'Facebook':
        return facebook
      case 'Instagram':
        return instagram
      case 'Linkedin':
        return linkedin
      default:
        return 'Facebook'
    }
  }

  useEffect(() => {
    if (currentUser !== null && currentUser?.social_media_scores?.length !== 0) {
      const updatedConnectedNetworksData = updateSocials()
      setConnectedNetworksData(updatedConnectedNetworksData)
    }
  }, [currentUser?.social_media_scores])

  useEffect(() => {
    const loadingTimeout = setTimeout(() => {
      setIsLoading(false)
    }, 500)

    return () => { clearTimeout(loadingTimeout) }
  }, [])

  const handleEdit = (network: Social, count: number) => {
    const updatedConnectedNetworksData = connectedNetworksData.map((item) => {
      if (item.name === network.name) {
        return {
          ...item,
          count
        }
      }

      return item
    })

    setConnectedNetworksData(updatedConnectedNetworksData)
  }

  useEffect(() => {
    const userGeneralScore = currentUser?.score ?? 0
    setData([{ name: 'L1', value: userGeneralScore }])
  }, [currentUser?.score])

  const [animatedValue, setAnimatedValue] = useState(0)
  useEffect(() => {
    let targetValue = currentUser?.score ?? 0
    targetValue = Math.ceil(targetValue)
    let startValue = 0
    const duration = 1500

    const increment = targetValue / (duration / 50)
    const interval = setInterval(() => {
      startValue += increment
      setAnimatedValue(Math.floor(Math.min(startValue, targetValue)))

      if (startValue >= targetValue) {
        clearInterval(interval)
      }
    }, 50)

    const scoreDiff = currentUser?.score != null && currentUser?.previous_score != null ? (currentUser?.score - currentUser?.previous_score) : 0
    setScoreDifferential(Math.ceil(scoreDiff))

    return () => { clearInterval(interval) }
  }, [currentUser?.score])

  // function getLevelName (level: number): string {
  //   switch (level) {
  //     case 0:
  //       return 'Beginner'
  //     case 1:
  //       return 'Climber'
  //     case 2:
  //       return 'Progressive'
  //     case 3:
  //       return 'Skilled'
  //     case 4:
  //       return 'Wizard'
  //     case 5:
  //       return 'Expert'
  //     case 6:
  //       return 'Guru'
  //     case 7:
  //       return 'Leader'
  //     case 8:
  //       return 'Exceptional'
  //     default:
  //       return 'Unknown'
  //   }
  // }

  return (
    <div className={className}>
      <div className='circular_page_eight flex flex-col md:flex-row justify-start rounded-2xl p-4 gap-5'>
        <div className='flex justify-center md:justify-between items-center'>
          <div className='flex flex-col items-center'>
            <RadialBarChart
              width={isSmallMobile ? 290 : (isMobile ? 290 : chartRadius * 2)}
              height={chartRadius * 2}
              innerRadius={chartRadius * 0.4}
              outerRadius={chartRadius * 0.9}
              barSize={35}
              data={data}
              startAngle={90}
              endAngle={-270}
              style={{ borderRadius: '20px', boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)' }}
            >
              <defs>
                <filter id="shadow" x="0" y="0" width="200%" height="200%">
                  <feOffset result="offOut" in="SourceAlpha" dx="5" dy="5" />
                  <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3" />
                  <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                </filter>
                <linearGradient id='fillGradient' x1='20%' y1='30%' x2='50%' y2='100%'>
                  <stop offset='0%' style={{ stopColor: '#733bb9', stopOpacity: 1 }} />
                  <stop offset='100%' style={{ stopColor: '#c1a9f7', stopOpacity: 1 }} />
                </linearGradient>
              </defs>
              <PolarAngleAxis
                type="number"
                domain={[0, 100]}
                angleAxisId={0}
                tick={false}
                style={{ filter: 'url(#shadow)' }}
              />
              <RadialBar background dataKey='value' fill='url(#fillGradient)' isAnimationActive={true} animationEasing='ease-in-out' />
              <text
                x={isSmallMobile ? 140 : (isMobile ? 290 : chartRadius)}
                y={isSmallMobile ? 60 : (isMobile ? 290 : chartRadius * 0.3)}
                textAnchor="middle"
                fill='#733bb9'
                dominantBaseline="middle"
                className="text-2xl font-semibold text-purple-600"
                style={{ filter: 'drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2))' }}
              >
                Branda Score
              </text>
              <text
                x={isSmallMobile ? 145 : (isMobile ? 290 : chartRadius)}
                y={isSmallMobile ? 185 : (isMobile ? 290 : chartRadius)}
                textAnchor="middle"
                dominantBaseline="middle"
                className="text-4xl font-medium "
              >
                {animatedValue}
              </text>
              {currentUser?.previous_score !== 0 && scoreDifferential !== 0
                ? (
                <>
                  <text
                    x={isSmallMobile ? 145 : (isMobile ? 290 : chartRadius - 10)}
                    y={isSmallMobile ? 215 : (isMobile ? 330 : chartRadius + 30)}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    style={{ fontSize: 10, fontWeight: 'bold', fill: scoreDifferential < 0 ? '#AA2222' : '#22AA22' }}
                  >
                    {scoreDifferential < 0 ? '▼' : '▲'}
                  </text>
                  <text
                    x={isSmallMobile ? 145 : (isMobile ? 290 : chartRadius)}
                    y={isSmallMobile ? 215 : (isMobile ? 330 : chartRadius + 30)}
                    textAnchor="middle"
                    dominantBaseline="middle"
                    className="text-xs font-medium"
                  >
                    {Math.abs(scoreDifferential)}
                  </text>
                </>
                  )
                : null}
            </RadialBarChart>
            <Link to="/all-weekly-goals">
              <Button
                text="Go to my weekly goals"
                className='button_bg flex mt-5 p-2 justify-center items-center rounded-lg text-white'
              />
            </Link>
          </div>
        </div>
        <div className='flex flex-col gap-3 justify-start items-start'>
          <Heading className='text-gray-400 font-manrope text-sm font-medium' text={`Last check date: ${currentDate}`} />
          <Heading className='text-black font-manrope text-xl font-medium' text={`Average Industry Score: ${currentUser?.profession_average_score ? Math.ceil(currentUser?.profession_average_score) : 0}`}/>
          {/* <Heading className='main_header_home font-manrope text-lg font-medium' text={`Your Brand Level: ${getLevelName(currentUser?.level ?? 0)}`}/> */}
          <div className='flex flex-col bg-purple-100 rounded-lg w-full p-2 gap-2' style={{ boxShadow: '5px 5px 5px rgba(0, 0, 0, 0.2)' }}>
            <div className='flex flex-row gap-2'>
              <img src={alertLogo} alt="alert" width='14px' height='14px' />
              <Heading className='text-purple-600 font-manrope text-sm font-medium' text='Your score is dynamic!' />
            </div>
            <Heading className='text-gray-600 block font-manrope text-sm font-medium' text='Please update your followers occasionally.' />
          </div>
          <Heading className='text-black font-manrope text-lg font-base mt-3' text='Your social media followers count' />
          <div className='flex flex-col divide-y bg-white rounded-lg w-full p-2'>
            <div className='pb-2'>
              <Heading className="text-black font-manrope text-sm font-medium mb-2" text="Your Active Profiles" />
              {isLoading
                ? (
                    <div className='flex justify-center'>
                      <CircularProgress />
                    </div>
                  )
                : connectedNetworksData.map((network, index) => (
                    <button key={index}
                      className="flex flex-row justify-between bg-purple-100 rounded-lg h-12 w-full gap-2 flex items-center p-3 mb-2"
                      onClick={() => { openPopup(network) }}
                    >
                      <div className="flex items-center justify-center">
                        <img src={network.image} className="h-8 block bg-center bg-no-repeat" />
                        <Heading className="font-manrope text-sm font-medium ml-3" text={network.name} />
                      </div>
                      <div className='flex justify-between'>
                        <span>
                          {network.no_followers}
                        </span>
                      </div>
                    </button>
                ))}
            </div>
            {isPopupOpen && selectedNetwork && (
              <Popup mode='edit' selectedNetwork={selectedNetwork} closePopup={closePopup} onSubmit={() => handleEdit} />
            )}
            <ConnectedNetworks onSubmit={() => handleConnectedNetworksSubmit} />
          </div>
        </div>
      </div>
    </div>
  )
}
