/* eslint-disable react/no-unescaped-entities */
import Footer from 'components/organisms/Footer/Footer'
import NavBar from 'components/organisms/NavBar/NavBar'
import React from 'react'

export default function PrivacyPage () {
  return (
    <>
      <NavBar className='home_navbar relative flex flex-row justify-between items-center p-5 pt-3 h-24 align-center -mb-5 sm:-mb-2' />
      <div className='bg-white'>
        <div className='bg-slate-100 mx-auto p-8 md:p-28 mt-4'>
          <h1 className='main_header_home font-extrabold text-2xl md:text-6xl'>
          Privacy Policy
            </h1>
          <p className='mt-8 text-base md:text-2xl'>
          Protecting your privacy is important to us. Read MyBranda's privacy policy to understand how we handle your data.
          </p>
        </div>
        <div className='container mx-auto mt-0 md:mt-14 p-9 text-left md:text-justify'>
        <h1 className='text-2xl md:text-5xl font-bold'>MyBranda Privacy Policy</h1>
        <p className='mt-4 md:mt-12 text-sm md:text-xl'>
        MyBrandaSoftware Ltd. (“we”,“us” or “MyBranda”) respects your right to privacy. Your ability to make informed choices about the use of your information is important
        to us.This privacy policy details how we collect, use and store information and data that we receive from our Site and/or Platform (together, the “Services”).
        </p>
        <p className='mt-4 text-sm md:text-xl'>
        Please also review the Terms of Service of theSolution and Site (the “Terms”). Terms capitalized but not defined here in have the meaning given to them in the Terms.
         As used in this privacy policy, “Personal Information” means information that can be directly associated with a specific person or entity such as a name, address,
          telephone number, or e-mail address.
        </p>
        <p className='mt-14 text-sm md:text-xl'>
          <h1 className='font-bold mb-4'> Information We Collect</h1>
          When you answer our published questionnaire, we collect your name, gender, profession, employment & educational status and email address. You may leave other
          credentials such as mobile phone number, country or other contact information if you choose.<br></br>
          In order to use our Services, you will be required to register and provide us with certain Personal Information. We also collectPersonal Information when you make
          use of the Services or contact us for any other reason, such as your name, email and phone number. If you use theService by logging in through a social network,
          we may also receive personal information about you which is provided by such social network. For example, if you log in through Facebook, we may have access to
          the list of your friends.Please check the policies of such social network in order to understand what information we receive.
          When you use the Services we collect the following Personal Information: your name, profession, title, email address, phone number and information from your various
          social network accounts. This information will include all social media activity available on users’ private and public social media profiles and groups to which
          you will connect our Services, including users’ bio, posts, text, video and image assets, all personal engagement, network and other activities performed by the
          user. Please check the policies of such social networks in order to understand what information we receive. In order to allow our access to your social networks,
          you will need to manually approve connecting each account and add your username, email and password.
          In addition, when you use our Services, certain information may be automatically gathered about your computer, browser, operating system, geo-location, IP address or
           mobile device.
          You are not required by law to provide us with any of the information described herein, but by using the Services you agree to provide us with such information.
        </p>
        <p className='mt-14 text-sm md:text-xl'>
          <h1 className='font-bold mb-4'> Calendar availability and integration</h1>
          You may connect your calendar with myBranda to enhance your productivity. Our calendar integration only allows scheduling the date and time of myBranda’s branding
          tasks in your calendar and allows you to access your calendar through our platform to secure available slots for tasks’ preparation time. We never store details
          about any other appointments or notes in your connected calendar, such as who you're meeting with, their email address, or the meeting title. Although connecting
          your calendar to our services makes your branding plan process much more efficient, you are not required to connect their calendar to use our services.
          It is disclosed that the app's use and transfer of information received from Google APIs to any other app will adhere to Google API Services User Data Policy, including the
          Limited Use requirements. You can read more about Google API Services here.
        </p>
        <p className='mt-14 text-sm md:text-xl'>
          <h1 className='font-bold mb-4'> How We Use Information</h1>
          We use the information that you provide when you contact us in order to respond to you and to provide you with the Services.<br></br>
          We may use Personal Information and other information internally – for example, to provide you with goods and services, to help diagnose problems with our servers,
          and to make our products and Services more useful for you and for our other customers. We will not share your Personal Information with third parties without your
          explicit permission, except when required by law, regulation, subpoena or court order or as otherwise expressly set forth herein.
        </p>

        <ul className='list-disc mt-10 text-sm md:text-lg mb-2 md:mb-8'>
          <span className='text-base md:text-xl'>We may share your Personal Information with:</span>
            <li className="mt-4 ml-4 md:ml-10">
                <p>
                members of our corporate family, or third party vendors or service providers, such as email service providers sending emails on our behalf. We may also authorize
                third party vendors or service providers to collect information on our behalf.  We will require members of our corporate  family and third party vendors to follow
                practices that protect your privacy rights on a level at least as restrictive as the practices described in this Privacy Policy.
                </p>
            </li>
            <li className="mt-4 ml-4 md:ml-10">
                <p>
                Business partners with whom we may jointly offer products or services, or whose products or services may be offered  on our Services. You can tell when a third party
                is involved in a product     or service because their name will appear, either alone or with ours. If     you choose to use these products or services, we may
                share information  about you, including your Personal Information, with those partners.Please note that we do not control the privacy practices of these third
                party business partners. If you wish to no longer allow that third party to use your information, please contact that third party directly.
                </p>
            </li>
            <li className="mt-4 ml-4 md:ml-10">
                <p>
                Referring websites. If you were referred to our Services from another site (for example, through a link), we may share some  information about you with that
                referring website. We encourage you to review the privacy policies of any website that referred you here.  Additionally, when you link to one of our partners
                from our Services, we  provide that partner with a unique identifier so they know that you were referred from our Services. This unique identifier will not contain
                any of your Personal Information.
                </p>
            </li>
        </ul>
        <p className='mt-8 text-sm md:text-xl'>
        We may compile statistical information across a variety of users (“Statistical Information”)in order to help us understand trends and customer needs.
        StatisticalInformation is anonymized and does not, in and of itself, contain any PersonalInformation. We may share Statistical Information with our commercial partners,
        pursuant to commercial terms that we determine in our sole discretion. We may provide non-Personal Information including ,but not limited to, Statistical Information
        to Google Analytics and other analogous platform in order to receive an analysis of the use of our Services. We may provide non-PersonalInformation that we receive
        to Google Analytics in order to receive an analysis of the use of our Services. Google Analytics collects only the IP address assigned to you on the date you visit
        this Services, rather than your name or other identifying information. We do not combine the information collected through the use of Google Analytics with Personal
        Information. Please see the Google Analytics Terms of Service (available at https://marketingplatform.google.com/about/analytics/terms/us/)and the Google Privacy Policy
        (available at https://policies.google.com/privacy) for additional information.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> How we Protect Information</h1>
          We follow generally accepted industry standards to protect the Personal Information submitted to us, both during transmission and once we receive it. However,
          no method of transmission over the Internet, or method of electronic storage is 100% secure.Therefore, while we strive to use commercially acceptable means to
          protect yourPersonal Information and other data, we cannot guarantee its absolute security.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Cookies</h1>
          A cookie isa small piece of text that is sent to a visitor's browser. The browser provides this piece of text to the device of the originating visitor when this
         visitor returns. We use cookies to help personalize your MyBranda experience. A“persistent” cookie may be used to help save your settings and customizations.Also,
         if you log in to the Services that we may provide, such a cookie will be used to recognize you as a valid user so you will not need to log in each time you use the
          Services. Most Web browsers are initially configured to accept cookies, but you can change this setting so your browser either refuses all cookies or informs you when
         a cookie is being sent. Also, you are free to delete any existing cookies at any time. Please note that some features of the Services may not function properly when
          cookies are disabled or removed. While the specific names and types of cookies we use may change from time to time, they generally fall into one of the categories
           listed below.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold'> Cookie Type Function</h1>
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Necessary</h1>
          These cookies allow the Services  to work correctly. They enable your access to the Services, move around, and  access different services, features, and tools.
           These cookies cannot be  disabled.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Functionality</h1>
          These cookies remember your  settings, preferences, and other choices you make (like placing an item in a  shopping cart) in order to help personalize and streamline
           your experience.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Security</h1>
          These cookies help us identify  and prevent security risks. They may be used to store your session  information to prevent others from changing your password without
           your login  information.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Performance/Analytics</h1>
          These cookies collect analytical  information to help us understand how you use our Services, for example  whether you have viewed messages, clicked on links, and how
           long you spent on  each page. This helps us improve our Services to better suit your needs.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Advertising </h1>
          These cookies help advertisers  show you ads. When we place advertising cookies, they help us track the  efficiency of our advertising campaigns. Advertising cookies
          may track your  browsing habits and activity when visiting our Services and those of third  parties and help us serve ads that are relevant and meaningful to you and
          your interests.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
        1.1. Third Party Cookies. In addition to our first-party cookies, we place cookies from the following[YA1]  third parties:
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Third Party Sites and Services </h1>
          We are not responsible for the use of any data by third parties, and we cannot vouch for the privacy policies of any third party. The Services may link to or use web sites
         or services belonging to third parties. We have no control over third-party sites or services, and all use of third-party sites or services is at your own risk.We cannot
          accept responsibility for the privacy policies of any such sites. We are not responsible for content available by means of such sites or services.We do not endorse any
           services offered by third parties and we urge our users to exercise caution in using third-party sites or services.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Use of Services by Children</h1>
          We do not knowingly collect personally identifiable information from children under the age of 13. In the event you become aware that somebody under the age of 13 has
           enrolled without parental permission, please inform us immediately.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Communications</h1>
          We may send you email or other messages about us or our services, for example if you sign up for our newsletter. By accepting the Terms of Service for the Services
          (including the terms of this PrivacyPolicy which are incorporated therein) or using the Services, you affirmatively consent to receive such commercial messages. As
           a registered member, you can remove your PersonalInformation from our mailing list and to stop receiving future communication from us by following the unsubscribe
          link located at the bottom of each communication orby emailing us at [ dana@mybranda.com  . You will also be given the opportunity to unsubscribe from commercial
           messages in any such email or communication we send.  Please note that we reserve the right to send you service related communications, including service
           announcements and administrative messages, relating either to your account, without offering you the opportunity to opt out of receiving them unless you cancel
           your account.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Correcting or Updating your Information</h1>
          MyBranda allows you to view the Personal Information you provided when contacting us and to alter any data, inaccuracies or errors.To request such access, or correct,
           update, or amend your PersonalInformation please contact us at the following e-mail address: dana@mybranda.com . We will retain and use your information, including
            Personal Information, as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements.
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> OtherUses or Transfer of Your Information</h1>
          We will fully cooperate with any law enforcement authorities or court order requesting or directing us to disclose the identity or behavior of any user.
          We may use subcontractors that are located in countries other than your own, and send themPersonal Information we receive. We may also share data with affiliates
          located in other countries. All our subcontractors will be subject to non-disclosure and non-use obligations. Nevertheless, some countries may have levels of
          protection of personal and other information which are not as high as in your country of residence or business.
          We may transfer our databases containing your Personal Information if we sell our business or part of it, including in cases of liquidation. Information about our
          users, including Personal Information, may be disclosed as part of, or during negotiations of, any merger, sale of company assets or acquisition and shall continue
          being subject to the provisions of this Privacy Policy. By providing such information, you expressly consent to such transfer and use, including transfers outside
          of the country of your residence
        </p>
        <p className='mt-8 text-sm md:text-xl'>
          <h1 className='font-bold mb-2'> Changes</h1>
          We may update this privacy policy from time to time, and we encourage you to review it periodically.
        </p>
        <p className='mt-8 text-sm md:text-xl mb-8'>
          <h1 className='font-bold mb-2'> Comments and Questions</h1>
          If you have any comments or questions about our privacy policy, please contact us at: contact@mybranda.com
        </p>
        <h1 className='mt-10 text-xs md:text-base mb-0 md:mb-8'>Last updated: February 24</h1>
        </div>
      </div>
      <Footer className='flex flex-col items-start md:items-center md:flex-row justify-between w-full min-h-20 p-3 pt-8 md:p-3 -mt-2 bg-white' />
    </>
  )
}
