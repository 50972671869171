import Header from '../../atoms/Heading/Heading'
import Icon from '../../atoms/Icon/Icon'
import Image from '../../atoms/Image/Image'
import QuizPageOneImg from '../../../img/Finish-Quiz-Icon-Bottom.png'
import { brands } from '@fortawesome/fontawesome-svg-core/import.macro'

export default function BrandaResultPage2Info2 () {
  return (
    <div className='flex flex-col justify-center items-center gap-3'>
        <Header className='font-medium text-white text-sm md:text-sm ' text='Share myBranda with friends' />
        <div className='flex flex-row justify-center items-center gap-10'>
            <a href='https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fwhatsmybranda.com%2F' target='_blank' rel="noreferrer">
              <Icon icon={brands('facebook')} style={{ color: '#1777f2', fontSize: '25pt' }} />
            </a>
            <a href="https://api.whatsapp.com/send?url=https%3A%2F%2Fwhatsmybranda.com%2F&text=Automatically build and manage your personal brand. Grow organically online and get noticed by customers with our AI Brand Coach.%0A%0Ahttps://whatsmybranda.com/" target='_blank' rel="noreferrer">
              <Icon icon={brands('square-whatsapp')} style={{ color: '#25D366', fontSize: '25pt' }} />
            </a>
            <a href="https://twitter.com/intent/tweet?url=https%3A%2F%2Fwhatsmybranda.com%2F&text=Automatically build and manage your personal brand. Grow organically online and get noticed by customers with our AI Brand Coach" target="_blank" rel="noreferrer">
              <Icon icon={brands('square-twitter')} style={{ color: '#50abf1', fontSize: '25pt' }} />
            </a>
            <a href='https://www.linkedin.com/shareArticle?mini=true&url=https%3A%2F%2Fwhatsmybranda.com%2F&title%3DAutomatically%20build%20and%20manage%20your%20personal%20brand.%20Grow%20organically%20online%20and%20get%20noticed%20by%20customers%20with%20our%20AI%20Brand%20Coach%26summary%3DGrow%20your%20business%20with%20a%20Personal%20Branding%20AI%20Coach&source=whatsmybranda.com' target='_blank' rel="noreferrer">
              <Icon icon={brands('linkedin')} style={{ color: '#1a82bb', fontSize: '25pt' }} />
            </a>
        </div>
        <div className='finish_bottom_text flex flex-row justify-center items-center gap-3 mt-5 mb-3 p-[5px] rounded-lg'>
            <Image className='' src={QuizPageOneImg} />
            <div className='flex flex-col justify-start items-start'>
                <Header className='text-xs font-normal text-white md:font-medium' text="Didn’t receive an email from us?" />
                <Header className='text-xs font-normal text-white md:font-medium' text="Please check your spam box" />
            </div>
        </div>
    </div>
  )
}
