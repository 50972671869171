import BrandaResultPage2Info from '../../molecules/BrandaResultPage2Info/BrandaResultPage2Info'
import BrandaResultPage2Form from '../../molecules/BrandaResultPage2Form/BrandaResultPage2Form'
import BrandaResultPage2Info2 from '../../molecules/BrandaResultPage2Info/BrandaResultPage2Info2'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function BrandaResultPage2 ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <BrandaResultPage2Info className=''/>
      <BrandaResultPage2Form className='' handleShowPage={handleShowPage}/>
      <BrandaResultPage2Info2/>
    </div>
  )
}
