import React, { useState, useEffect } from 'react'
import Heading from '../../atoms/Heading/Heading'
import ToDo from '../WeeklyGoalsInfo/ToDo'
import { useNavigate, useParams } from 'react-router-dom'
import { tasks } from 'app/services/Api'
import { type Task } from 'app/quiz/quizSlice'
import BackButton from 'components/atoms/BackButton/BackButton'
// import { gapi } from "gapi-script";

interface Props {
  className?: string
}

export default function WeeklyGoalsFrom ({ className }: Props) {
  const [selectedItem, setSelectedItem] = useState('To do')
  const [backPage] = useState('/all-weekly-goals')
  const [task, setTask] = useState<Task>()
  const navigate = useNavigate()
  const { id } = useParams()
  let value: number
  if (id != null) value = parseInt(id)

  useEffect(() => {
    const getTask = async () => {
      await tasks
        .getTask(value)
        .then((response) => {
          setTask(response)
        })
        .catch((err) => {
          console.log(err)
        })
    }

    void getTask()
  }, [])

  const handleBackPage = () => {
    navigate(backPage)
    window.scrollTo(0, 0)
  }

  return (
    <div className="flex flex-col justify-center items-center md:justify-start md:items-start w-full gap-3">
      <div className="flex w-full mb-4">
        <BackButton
          backPage={backPage}
          handleBackPage={() => { handleBackPage() }}
          text="Back"
          className="quiz_back_button block"
        />
      </div>
      <Heading
        className="flex justify-start font-medium tracking-wide text-xl md:text-4xl leading-7 text-black block p-0"
        text="Your Weekly Goals"
      />
      <div className="flex flex-row gap-7 pt-4">
        <div className="flex">
          <button
            onClick={() => { setSelectedItem('To do') }}
            className={`cursor-pointer ${
              selectedItem === 'To do'
                ? 'underline underline-offset-[20px] text-base md:text-lg  underline-purple-100'
                : 'text-gray-500 text-base md:text-lg '
            }`}
          >
            To do
          </button>
          <div className="ml-2 bg-black px-2  text-white rounded-md text-center">
            1
          </div>
        </div>
      </div>

      {selectedItem === 'To do' && <ToDo task={task} />}
    </div>
  )
}
