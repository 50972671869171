import React from 'react'
import { useMediaQuery } from 'react-responsive'

export default function HomeVideo () {
  const isMobile = useMediaQuery({ query: '(max-width: 728px)' })

  const defaultVideoUrl = 'https://www.youtube.com/embed/4wI0x3xtrxA'
  const mobileVideoUrl = 'https://www.youtube.com/embed/5uuHuLdpfOw'

  return (
    <div className="flex flex-col bg-purple-100 rounded-3xl mb-6 p-3 d:p-8">
      <div className="mb-1 items-start p-5 pb-3">
        <h1 className="text-xl md:text-3xl font-medium mb-4">
          Discover How myBranda Can Help You Build Your Brand!
        </h1>
        <p className="text-sm md:text-lg text-gray-600 mb-4">
          Check out our product video below to learn about our innovative branding process!
        </p>
      </div>
      <div className="flex flex-col items-center">
        {isMobile
          ? (
            <div className="relative mb-6 video-iframe overflow-auto">
            <iframe
              className="w-full h-full rounded-3xl border-black border-8"
              src={mobileVideoUrl}
              title="myBranda Product Video"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; gesture"
              allowFullScreen
            ></iframe>
          </div>
            )
          : (
            <div className="relative mb-6 video-iframe">
              <iframe
                className="w-full h-full rounded-3xl border-black border-8"
                src={defaultVideoUrl}
                title="myBranda Product Video"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              ></iframe>
            </div>
            )}
        <button className="bg-purple-700 hover:bg-purple-800 text-lg text-white font-normal py-4 px-16 rounded-2xl">
          <a href="https://whatsmybranda.com/quiz">
            Build My Brand!
          </a>
        </button>
      </div>
    </div>
  )
}
