import React from 'react'
import QuizGenderPageInfo from '../../molecules/QuizGenderPageInfo/QuizGenderPageInfo'
import QuizGenderPageForm from '../../molecules/QuizGenderPageForm/QuizGenderPageForm'

interface Props {
  className: string
  handleShowPage: (page: string) => void
}

export default function QuizGenderPage ({ className, handleShowPage }: Props) {
  return (
    <div className={className}>
      <QuizGenderPageInfo className='flex flex-col visible_1 relative justify-center items-center gap-2 mt-10'/>
      <QuizGenderPageForm handleShowPage={handleShowPage} className='flex flex-col justify-center items-center relative visible_1'/>
    </div>
  )
}
