/* eslint-disable @typescript-eslint/strict-boolean-expressions */
import React, { useEffect, useState } from 'react'
import QuizEmailPage from '../../organisms/QuizEmailPage/QuizEmailPage'
import QuizGoalsPage from '../../organisms/QuizGoalsPage/QuizGoalsPage'
import QuizFirstStep from '../../organisms/QuizFirstStep/QuizFirstStep'
import QuizThirdStep from '../../organisms/QuizThirdStep/QuizThirdStep'
import QuizSecondStep from '../../organisms/QuizSecondStep/QuizSecondStep'
import BackButton from '../../atoms/BackButton/BackButton'
import QuizGenderPage from '../../organisms/QuizGenderPage/QuizGenderPage'
import QuizProfessionPage from '../../organisms/QuizProfessionPage/QuizProfessionPage'
import QuizVerificationPage from '../../organisms/QuizVerificationPage/QuizVerificationPage'
import BrandaResult from '../../organisms/BrandaResult/BrandaResult'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setIsLoading } from '../../../app/quiz/quizSlice'
import CircularWithValueLabel from '../../organisms/CircularProgress/CircularProgress'
import BrandaResultPage2 from '../../organisms/BrandaResultPage2/BrandaResultPage2'
import QuizBirthdatePage from '../../organisms/QuizBirthdatePage/QuizBirthdatePage'
import BrandaThankYouPage from '../../organisms/BrandaThankYouPage/BrandaThankYouPage'
import QuizPasswordPage from '../../organisms/QuizPasswordPage/QuizPasswordPage'
import QuizExpertise from 'components/organisms/QuizExpertisePage/QuizExpertise'

export default function Quiz () {
  const storedCurrentPage = localStorage.getItem('quizCurrentPage')
  const initialPage = storedCurrentPage ?? 'pageOne'

  const [currentPage, setCurrentPage] = useState(initialPage)
  const [backPage, setBackPage] = useState('')
  const isLoading = useAppSelector((state) => state.quizReducer.isLoading)
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const dispatch = useAppDispatch()

  useEffect(() => {
    if (currentUser?.quiz_step?.step_name === 'Goals') {
      setCurrentPage('pageFour')
    } else if (currentUser?.quiz_step?.step_name === 'Gender') {
      setCurrentPage('pageFive')
    } else if (currentUser?.quiz_step?.step_name === 'Birthdate') {
      setCurrentPage('pageSix')
    } else if (currentUser?.quiz_step?.step_name === 'Profession') {
      setCurrentPage('pageSeven')
    } else if (currentUser?.quiz_step?.step_name === 'Keywords') {
      setCurrentPage('pageEight')
    } else if (currentUser?.quiz_step?.step_name === 'Step One') {
      setCurrentPage('pageStep1')
    } else if (currentUser?.quiz_step?.step_name === 'Step Two') {
      setCurrentPage('pageStep2')
    } else if (currentUser?.quiz_step?.step_name === 'Step Three') {
      setCurrentPage('pageStep3')
    } else if (currentUser?.quiz_step?.step_name === 'Page Result') {
      setCurrentPage('pageBrandaResult2')
    } else if (currentUser?.quiz_step?.step_name === 'Page Thank You') {
      setCurrentPage('pageBrandaThankYou')
    }
  }, [currentUser])

  useEffect(() => {
    if (currentPage === 'pageFour') {
      setBackPage('pageThree')
    } else if (currentPage === 'pageFive') {
      setBackPage('pageFour')
    } else if (currentPage === 'pageSix') {
      setBackPage('pageFive')
    } else if (currentPage === 'pageSeven') {
      setBackPage('pageSix')
    } else if (currentPage === 'pageEight') {
      setBackPage('pageSeven')
    } else if (currentPage === 'pageStep1') {
      setBackPage('pageSix')
    } else if (currentPage === 'pageStep2') {
      setBackPage('pageStep1')
    } else if (currentPage === 'pageStep3') {
      setBackPage('pageStep2')
    }

    localStorage.setItem('quizCurrentPage', currentPage)
  }, [currentPage])

  const handleShowPage = (page: string) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  const handleBackPage = (page: string) => {
    setCurrentPage(page)
    window.scrollTo(0, 0)
  }

  let button
  if (
    currentPage === 'pageOne' ||
    currentPage === 'pageTwo' ||
    currentPage === 'pageThree' ||
    currentPage === 'pageFour' ||
    currentPage === 'pageBrandaResult' ||
    currentPage === 'pageBrandaResult2' ||
    currentPage === 'pageBrandaThankYou' ||
    currentPage === 'pageStep1' ||
    currentPage === 'pageStep2' ||
    currentPage === 'pageStep3'
  ) {
    button = null
  } else {
    button = (
      <BackButton
        backPage={backPage}
        handleBackPage={handleBackPage}
        text="Back"
        className="quiz_back_button z-10 block absolute"
      />
    )
  }

  useEffect(() => {
    if (currentPage === 'pageBrandaResult') {
      setTimeout(() => {
        dispatch(setIsLoading(false))
      }, 9000)
    }
  }, [dispatch, currentPage])

  let pageBrandaResult
  if (!(isLoading) && currentPage === 'pageBrandaResult') {
    pageBrandaResult = (
      <BrandaResult
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaResult'
            ? 'branda_result visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_result hidden'
        }
      />
    )
  } else if (isLoading && currentPage === 'pageBrandaResult') {
    pageBrandaResult = (
      <div className='circular_page_eight bg-white flex justify-center items-center rounded-3xl relative basis-11/12 md:basis-11/12 lg:basis-9/12 py-28'>
        <CircularWithValueLabel />
      </div>
    )
  }

  return (
    <>
      {button != null && (
        <div className="relative">
          {button}
        </div>
      )}

      <QuizEmailPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageOne'
            ? 'quiz_email_page visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-10 md:mb-28'
            : 'quiz_email_page hidden'
        }
      />

      <QuizVerificationPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageTwo'
            ? 'quiz_verification_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_verification_page hidden'
        }
      />

      <QuizPasswordPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageThree'
            ? 'quiz_password_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 basis-11/12 md:basis-11/12 lg:basis-9/12 mb-40 md:mb-48'
            : 'quiz_password_page hidden'
        }
      />

      <QuizGoalsPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageFour'
            ? 'quiz_goals_page visible relative flex flex-col justify-center items-center bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_goals_page hidden'
        }
      />

      <QuizGenderPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageFive'
            ? 'quiz_gender_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_gender_page hidden'
        }
      />

      <QuizBirthdatePage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageSix'
            ? 'quiz_birthdate_page visible relative flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28 md:mb-28'
            : 'quiz_birthdate_page hidden'
        }
      />

      <QuizProfessionPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageSeven'
            ? 'quiz_profession_page visible flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24'
            : 'quiz_profession_page hidden'
        }
      />

      <QuizExpertise
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageEight'
            ? 'quiz_expertise_page visible flex flex-col justify-center items-center relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-28'
            : 'quiz_expertise_page hidden'
        }
      />

      <QuizFirstStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep1'
            ? 'quiz_first_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 py-20 mb-24'
            : 'quiz_first_step hidden'
        }
      />
      <QuizSecondStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep2'
            ? 'quiz_second_step visible bg-white rounded-3xl relative w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-10 mb-24'
            : 'quiz_second_step hidden'
        }
      />
      <QuizThirdStep
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageStep3'
            ? 'quiz_third_step visible relative bg-white rounded-3xl w-full basis-11/12 md:basis-11/12 lg:basis-9/12 pt-20 pb-10 mb-24'
            : 'quiz_third_step hidden'
        }
      />

      {pageBrandaResult}

      <BrandaResultPage2
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaResult2'
            ? 'branda_result_page_2 visible visible_1 flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full pb-20 pt-10 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_result_page_2 hidden'
        }
      />
      <BrandaThankYouPage
        handleShowPage={handleShowPage}
        className={
          currentPage === 'pageBrandaThankYou'
            ? 'branda_thank_you_page visible visible_1 bg-white flex flex-col justify-center items-center gap-2 bg-white rounded-3xl w-full py-20 left-0 md:mx-auto md:mt-0 mb-24'
            : 'branda_thank_you_page hidden'
        }
      />
    </>
  )
}
