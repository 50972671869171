/* eslint-disable @typescript-eslint/no-confusing-void-expression */
/* eslint-disable @typescript-eslint/strict-boolean-expressions */
/* eslint-disable @typescript-eslint/no-misused-promises */
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import Icon from '../../atoms/Icon/Icon'
import CustomLink from '../../atoms/Link/Link'
import { Link, NavLink, useNavigate } from 'react-router-dom'
import Button from '../../atoms/Button/Button'
import { useAppDispatch, useAppSelector } from '../../../app/hooks'
import { setCurrentUser } from '../../../app/quiz/quizSlice'
import { users } from '../../../app/services/Api'
import { useState } from 'react'
import { toast } from 'react-toastify'
interface Props {
  handleClick: (collapsed: boolean) => void
  collapsed: boolean
}

export default function QuizNavbar ({ handleClick, collapsed }: Props) {
  const currentUser = useAppSelector(state => state.quizReducer.currentUser)
  const [showMenu, setShowMenu] = useState(false)

  const toggleMenu = () => {
    setShowMenu((prev) => !prev)
  }

  const toggleNavbar = () => {
    setShowMenu((prev) => !prev)
    if (!collapsed) {
      handleClick(true)
    }
  }

  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const handleLogOut = async () => {
    await users.logOut(true).then(response => {
      dispatch(setCurrentUser(null))
      navigate('/')
      toast.success('Successfully logged out!')
    })
      .catch(err => {
        console.log(err)
      })
  }

  return (
    <div className="quiz_navbar relative flex flex-row justify-between items-center p-5">
      <CustomLink
        className="my_branda_logo ml-5 flex self-center text-black font-medium text-2xl"
        linkTo="/"
        name="myBranda"
        tm={true}
      />
      <div className='flex items-center '>
        {currentUser
          ? (
            <>
              <div className='flex justify-center items-center relative'>
                <button className='mb-2 flex items-center justify-between cursor-pointer' onClick={toggleMenu} >
                  <div className='flex items-center'>
                    <div className='mr-4 rounded-full h-9 w-9 bg-indigo-800 flex items-center justify-center'>
                      <h2 className='text-white font-normal text-xs'>{currentUser.first_name.charAt(0) + currentUser.last_name.charAt(0)}</h2>
                    </div>
                    <div className='hidden lg:block mr-10'>
                      <h1 className='text-base font-light flex items-center'>
                        {currentUser.first_name}
                      </h1>
                      <p className='text-xs font-thin'>{currentUser.profession}</p>
                    </div>
                  </div>
                  <div className="lg:flex flex-row justify-between items-center hidden"></div>
                </button>

                {showMenu && (
                  <div className="absolute md:ml-[-3rem] mt-32 md:mt-32 p-1 bg-purple-100 rounded-xl shadow-md w-[155px] right-3 md:right-14 md:w-auto z-10">
                    <NavLink to="/profile" onClick={toggleNavbar} className="block md:w-32 py-2 px-4 hover:bg-purple-300 rounded-lg text-sm">My Profile</NavLink>
                    <button onClick={handleLogOut} className="block py-2 px-4 w-full flex items-start hover:bg-rose-200 rounded-lg text-sm text-red-500">Log Out</button>
                  </div>
                )}
              </div>
            </>
            )
          : (
            <div className='mr-4'>
              <Link to="/sign-in">
                <Button
                  text="Sign In"
                  className='sign_in_button border border-white text-xs !w-20 md:!w-44 md:text-base flex p-3 justify-center items-center gap-3 rounded-lg text-white'
                />
              </Link>
            </div>
            )}
        <div onClick={() => { handleClick(!collapsed) }} className='lg:hidden'>
          <Icon icon={solid('bars')} style={{ color: '#000000', fontSize: '20pt' }} />
        </div>
      </div>
    </div>
  )
}
