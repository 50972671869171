const subscriptionPlans = [
  {
    id: 0,
    branda_type: 'Brand Booster',
    price: '$10',
    img: 'images/home-brand-booster.svg',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 4 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: 'https://whatsmybranda.com/quiz'
  },
  {
    id: 1,
    branda_type: 'Brand Star',
    price: '$25',
    img: 'images/home-brand-star.png',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 12 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: 'https://whatsmybranda.com/quiz'
  },
  {
    id: 2,
    branda_type: 'Brand Master',
    price: '$50',
    img: 'images/home-brand-master.svg',
    title: 'Your Personal Brand Identity \n Personal Branding score \n Personalized 48 weeks plan \n Personalized content suggestions \n Personal task reminders',
    paypro_url: ''
  }
]

export default subscriptionPlans
