/* eslint-disable */
import { type ApiClient } from '../ApiClient'

export class Users {
  private readonly api: ApiClient

  constructor (api: ApiClient) {
    this.api = api
  }

  async updateRegisteredUser(id: number, updateData: object, credentials?: boolean): Promise<any> {
    return await this.api.patch<object>(`users/${id}/`, updateData, credentials)
  }

  async register (
    email: string,
    password: string,
    confirmPassword: string,
    first_name: string,
    last_name: string,
    goal_q1: string,
    goal_q2: string,
    country: string,
    weekly_fb_posts_number: string | null,
    weekly_ig_posts_number: string | null,
    weekly_ln_posts_number: string | null,
    weekly_tw_posts_number: string | null,
    social_media_usage: string[],
    birthdate: string,
    profession: string,
    gender: string,
    fb_posts: string,
    ig_posts: string,
    expertise_keywords: string,
  ): Promise<any> {
    return await this.api.post<object>('users/register_user/', {
      email,
      password,
      confirm_password: confirmPassword,
      first_name,
      last_name,
      goal_q1,
      goal_q2,
      country,
      weekly_fb_posts_number,
      weekly_ig_posts_number,
      weekly_ln_posts_number,
      weekly_tw_posts_number,
      social_media_usage,
      birthdate,
      profession,
      gender,
      fb_posts,
      ig_posts,
      expertise_keywords
    })
  }

  async registerPartial (
    email: string,
    password: string,
    confirmPassword: string,
    first_name: string,
    last_name: string
  ): Promise<any> {
    return await this.api.post<object>('users/register_user/', {
      email,
      password,
      confirm_password: confirmPassword,
      first_name,
      last_name
    })
  }

  async signIn (
    email: string,
    password: string,
    credentials: boolean,
    branda_name?: string
  ): Promise<any> {
    return await this.api.post<object>(
      'users/login_user/',
      { email, password, branda_name },
      credentials
    )
  }

  async getCurrentUser (credentials?: boolean): Promise<any> {
    return await this.api.get<object>('users/current_user/', credentials)
  }

  async updateUserBilling (subscription_type: number, request_action: string, reason_text: string, renewal?: boolean, renewal_id?: number): Promise<any> {
    return await this.api.post<object>('users/billing_update/', { subscription_type, request_action, reason_text, renewal, renewal_id}, true)
  }

  async changePaymentMethod (credentials?: boolean): Promise<any> {
    return await this.api.post<object>('users/change_payment_method/', undefined, credentials)
  }

  async updateUser(id: number, updateData: object, credentials?: boolean): Promise<any> {
    return await this.api.patch<object>(`users/${id}/`, updateData, credentials)
  }

  async deleteUser (id: number, credentials?: boolean): Promise<any> {
    return await this.api.post<object>('users/delete_user/', { id }, credentials)
  }
  
  async validatePassword (password: string): Promise<any> {
    return await this.api.post<object>('users/validate_password/', { password })
  }

  async getAllUsers(name?: string): Promise<any> {
    const queryParam = name ? `?name=${name}` : '';
    return await this.api.get<object[]>(`users/${queryParam}`)
  }

  async getUserTasks (credentials?: boolean): Promise<any> {
    return await this.api.get<object>('user_tasks/', credentials)
  }

  async logOut (credentials?: boolean): Promise<any> {
    return await this.api.post<object>(
      'users/logout_user/',
      undefined,
      credentials
    ).then(() => {
      localStorage.clear()
    })
  }

  async getAIGeneratedTask (taskId: number, credentials?: boolean): Promise<any> {
    return await this.api.get<object>(`ai_predictions/${taskId}/`, credentials)
  }

  async postAIGenerated (user_task_id: number): Promise<any> {
    return await this.api.post<object>(`ai_predictions/generate_prediction/`, { user_task_id }, true)
  }
 
  async updateUserTask (taskId: number, completed?: boolean, language?: string, copied?: boolean, postpone_count?: boolean, personalization?: string): Promise<any> {
    return await this.api.put<object>(`tasks/${taskId}/`, { completed, language, copied, postpone_count, personalization }, true)
  }

  async addBrandWaitingList (email: string): Promise<any> {
    return await this.api.post<object>('brand_waiting_lists/', { email })
  }

  async userFreeTrail(trial_key: string, email: string): Promise<any> {
    return await this.api.post<object[]>('trial_keys/', { trial_key, email }, true)
  }
}
