import Heading from '../../atoms/Heading/Heading'
import { ReactComponent as QuizGoalsPageImg } from '../../../img/quiz-goals-page-img.svg'

interface Props {
  className: string
}

export default function QuizGoalsPageInfo ({ className }: Props) {
  return (
    <div className={className}>
      <QuizGoalsPageImg className='w-72 md:w-full' />
      <Heading className='quiz_page_two_h1 text-2xl font-medium mt-7' text='Choose your 2 online goals'/>
      <div className='flex flex-col justify-center items-center gap-1 mt-3'>
        <Heading className='quiz_page_two_h2 text-base font-normal' text='What are you looking to achieve with us?' />
        <Heading className='quiz_page_two_h3 text-sm font-normal' text='The 1st choice selected is your most important goal' />
      </div>
    </div>
  )
}
